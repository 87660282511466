import classNames from 'classnames';
import { Item } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  deleteDocument,
  updateDocumentsStatusDeleted,
} from '../../../redux/reducers/documentsStatusSlice';
import { deleteDocuments } from '../../../db/documentDBAction';
import { selectRows, updateLeaseDocumentDeleted } from '../../../redux/reducers/documentsDataSlice';
import { selectSelectedDocumentExpandedId, selectSelectedDocumentId, setDeSelectDocument, setDocumentDeleted, setDocumentExpanded, setSelectedDocument } from '../../../redux/reducers/dashboardSlice';
import { selectInboxId } from '../../../redux/reducers/metadataSlice';
import { updateParentsWithChildren } from '../../../redux/reducers/documentsSlice';

function RowContextMenu(props: any) {
  const dispatch = useAppDispatch();
  const selectedDocumentId = useAppSelector(selectSelectedDocumentId);
  const inboxId = useAppSelector(selectInboxId);
  const inboxDocuments = useAppSelector(selectRows)[inboxId];
  const expandedDocumentId = useAppSelector(selectSelectedDocumentExpandedId);
  
  const handleDeleteDocument = () => {
    const loiDoc = props.propsFromTrigger.data;
    const isChildExists = inboxDocuments.filter(e => e.parent === loiDoc.parent).length;
    const checkIfOnlyChild = isChildExists === 1;
    deleteDocuments([loiDoc.id]).then(() => {
      if (checkIfOnlyChild) {
        dispatch(updateParentsWithChildren(loiDoc.parent));
        dispatch(setDocumentExpanded(''));
      }
      // Parent is being deleted
      if (isChildExists && loiDoc.parent === '' && loiDoc.id === expandedDocumentId) {
        dispatch(setDocumentExpanded(''));
      }
      dispatch(deleteDocument(loiDoc.id)).then(() => {
        if (selectedDocumentId === loiDoc.id) {
          dispatch(setDeSelectDocument);
        }
        dispatch(setDocumentDeleted(true));
      });
    });
  };

  return (
    <section>
      <Item
        key='rowContextMenu'
        id='rowContextMenu'
        className={classNames('context-menu-item')}
        onClick={handleDeleteDocument}>
        <span className={classNames('menu-status-text', 'context-menu-text')}>Delete LOI</span>
      </Item>
    </section>
  );
}
export default RowContextMenu;
