// Logo
//1280
import DashboardLogo_1280 from "../assets/images/dashboard/logo/dashboard/1280.svg";
import UploadLogo_1280 from "../assets/images/dialog/upload/logo/1280.svg";
//1366
import DashboardLogo_1366 from "../assets/images/dashboard/logo/dashboard/1366.svg";
import UploadLogo_1366 from "../assets/images/dialog/upload/logo/1366.svg";
//1920
import DashboardLogo_1920 from "../assets/images/dashboard/logo/dashboard/1920.svg";
import UploadLogo_1920 from "../assets/images/dialog/upload/logo/1920.svg";
//2048
import DashboardLogo_2048 from "../assets/images/dashboard/logo/dashboard/2048.svg";
import UploadLogo_2048 from "../assets/images/dialog/upload/logo/2048.svg";

import UserIcon_New from "../assets/images/dashboard/header/user/user.svg";
import UserIcon_1280 from "../assets/images/dashboard/header/user/1280.svg";
import UserIcon_1366 from "../assets/images/dashboard/header/user/1366.svg";
import UserIcon_1920 from "../assets/images/dashboard/header/user/1920.svg";
import UserIcon_2048 from "../assets/images/dashboard/header/user/2048.svg";

// Row Pinning
//1280
import PinnedRow_1280 from "../assets/images/dashboard/table/pinned-row/1280.svg";
import UnPinnedRow_1280 from "../assets/images/dashboard/table/unpinned-row/1280.svg";
//1366
import PinnedRow_1366 from "../assets/images/dashboard/table/pinned-row/1366.svg";
import UnPinnedRow_1366 from "../assets/images/dashboard/table/unpinned-row/1366.svg";
import RowDragIcon from "../assets/images/dashboard/table/dragRowIcon.svg";
import RowSortIcon from "../assets/images/dashboard/table/RowSortIcon.svg";

//1920
import PinnedRow_1920 from "../assets/images/dashboard/table/pinned-row/1920.svg";
import UnPinnedRow_1920 from "../assets/images/dashboard/table/unpinned-row/1920.svg";
//2048
import PinnedRow_2048 from "../assets/images/dashboard/table/pinned-row/2048.svg";
import UnPinnedRow_2048 from "../assets/images/dashboard/table/unpinned-row/2048.svg";

// Settings
import Settings_1280 from "../assets/images/dashboard/table/settings/1280.svg";
import Settings_1366 from "../assets/images/dashboard/table/settings/1366.svg";
import Settings_1920 from "../assets/images/dashboard/table/settings/1920.svg";
import Settings_2048 from "../assets/images/dashboard/table/settings/2048.svg";

// Tab
import Tab_Active_Left_1280 from "../assets/images/dashboard/table/tab/active/Active_Left_1280.svg";
import Tab_Active_Right_1280 from "../assets/images/dashboard/table/tab/active/Active_Right_1280.svg";
import Tab_Inactive_Left_1280 from "../assets/images/dashboard/table/tab/inactive/Inactive_Left_1280.svg";
import Tab_Inactive_Right_1280 from "../assets/images/dashboard/table/tab/inactive/Inactive_Right_1280.svg";

import Tab_Active_Left_1366 from "../assets/images/dashboard/table/tab/active/Active_Left_1366.svg";
import Tab_Active_Right_1366 from "../assets/images/dashboard/table/tab/active/Active_Right_1366.svg";
import Tab_Inactive_Left_1366 from "../assets/images/dashboard/table/tab/inactive/Inactive_Left_1366.svg";
import Tab_Inactive_Right_1366 from "../assets/images/dashboard/table/tab/inactive/Inactive_Right_1366.svg";

import Tab_Active_Left_1920 from "../assets/images/dashboard/table/tab/active/Active_Left_1920.svg";
import Tab_Active_Right_1920 from "../assets/images/dashboard/table/tab/active/Active_Right_1920.svg";
import Tab_Inactive_Left_1920 from "../assets/images/dashboard/table/tab/inactive/Inactive_Left_1920.svg";
import Tab_Inactive_Right_1920 from "../assets/images/dashboard/table/tab/inactive/Inactive_Right_1920.svg";

import Tab_Active_Left_2048 from "../assets/images/dashboard/table/tab/active/Active_Left_2048.svg";
import Tab_Active_Right_2048 from "../assets/images/dashboard/table/tab/active/Active_Right_2048.svg";
import Tab_Inactive_Left_2048 from "../assets/images/dashboard/table/tab/inactive/Inactive_Left_2048.svg";
import Tab_Inactive_Right_2048 from "../assets/images/dashboard/table/tab/inactive/Inactive_Right_2048.svg";

// Column Pin
import ColumnPin_1280 from "../assets/images/dashboard/table/pin-column/1280.svg";
import ColumnPin_1366 from "../assets/images/dashboard/table/pin-column/1366.svg";
import ColumnPin_1920 from "../assets/images/dashboard/table/pin-column/1920.svg";
import ColumnPin_2048 from "../assets/images/dashboard/table/pin-column/2048.svg";

import ColumnUnPin_1280 from "../assets/images/dashboard/table/unpinned-column/1280.svg";

// Status Images
// 1280
import Completed_1280 from "../assets/images/dashboard/table/status/completed/1280.svg";
import StatusHeader_1280 from "../assets/images/dashboard/table/status/header/1280.svg";
import LOIsWorking_1280 from "../assets/images/dashboard/table/status/lois-working/1280.svg";
//1366
import Completed_1366 from "../assets/images/dashboard/table/status/completed/1366.svg";
import StatusHeader_1366 from "../assets/images/dashboard/table/status/header/1366.svg";
import LOIsWorking_1366 from "../assets/images/dashboard/table/status/lois-working/1366.svg";
//1920
import Completed_1920 from "../assets/images/dashboard/table/status/completed/1920.svg";
import StatusHeader_1920 from "../assets/images/dashboard/table/status/header/1920.svg";
import LOIsWorking_1920 from "../assets/images/dashboard/table/status/lois-working/1920.svg";
// 2048
import Completed_2048 from "../assets/images/dashboard/table/status/completed/2048.svg";
import StatusHeader_2048 from "../assets/images/dashboard/table/status/header/2048.svg";
import LOIsWorking_2048 from "../assets/images/dashboard/table/status/lois-working/2048.svg";

import HideNotes_1280 from "../assets/images/dashboard/table/notes/hide/1280.svg";
import HideNotes_1366 from "../assets/images/dashboard/table/notes/hide/1366.svg";
import HideNotes_1920 from "../assets/images/dashboard/table/notes/hide/1920.svg";
import HideNotes_2048 from "../assets/images/dashboard/table/notes/hide/2048.svg";

import ShowNotes from "../assets/images/dashboard/table/ShowNotes.svg";

// Children
//1280
import HideChildren_1280 from "../assets/images/dashboard/table/hide/1280.svg";
import ShowChildren_1280 from "../assets/images/dashboard/table/show/1280.svg";
//1366
import HideChildren_1366 from "../assets/images/dashboard/table/hide/1366.svg";
import ShowChildren_1366 from "../assets/images/dashboard/table/show/1366.svg";
//1920
import HideChildren_1920 from "../assets/images/dashboard/table/hide/1920.svg";
import ShowChildren_1920 from "../assets/images/dashboard/table/show/1920.svg";
//2048
import HideChildren_2048 from "../assets/images/dashboard/table/hide/2048.svg";
import ShowChildren_2048 from "../assets/images/dashboard/table/show/2048.svg";

import _, { uniqueId } from "lodash";
import NewTab from "../assets/images/dashboard/table/tab/NewTab.svg";
import TabHide from "../assets/images/dashboard/table/tab/TabHide.svg";
import TabSelection from "../assets/images/dashboard/table/tab/TabSelection.svg";
import TabShow from "../assets/images/dashboard/table/tab/TabShow.svg";
import { getBlankDocumentData } from "../components/content/table/table-util";
import { DocumentEntity } from "./types/EntityTypes";

export const SUPPORTED_FILE_FORMATS = ["docx", "doc", "pdf"];

export const CLIENT_NAME_COUNTER_STORAGE_KEY = "client_count";
export const DELETE_ACTION_DOC_IDS = "FILTER_DOC_IDS";
export const CLIENT_DUMMY_ROW_IDS = "DUMMY_ROW_IDS";
// Menu IDs don't delete
export const NONE_MENU_ID = "none-menu-id";
export const MARKET_MENU_ID = "market-menu-id";
export const AUTHOR_MENU_ID = "author-menu-id";
export const STATUS_MENU_ID = "status-menu-id";
export const STATUS_FILTER_MENU_ID = "status-filter-menu-id";
export const CONTEXT_MENU_ID = "apdt-context-menu-id";
export const DELETE_FILTER_WARNING_ID = "apdt-delete-filter-warning-id";

export const TAB_ACTION_MENU_ID = "tab-action-menu-id";
export const TAB_VIEW_MENU_ID = "tab-view-menu-id";
export const TAB_VIEW_SHARE_DOC_ID = "tab-share-id";
// Menu IDs

// DBs Constants
export const PROD_ENV = "prod";
export const DEV_ENV = "dev";

export const DB_NAME = "apdt";
export const DB_NAME_DEV = "apdt-dev";

export const TOOL_TIP_TAB = "tab-tool-tip";

export const TOOL_TIP_UPLOAD = "upload-tool-tip";

export const DEFAULT_TAB_NAME = "Inbox";

export const DEFAULT_AUTHOR_NAME = "Select Author";

export const DEFAULT_MARKET = "No Market";

export const DEFAULT_NEW_TAB_NAME = "New Filter";

export const NOTIFICATION_TYPE = {
  PING: "PING",
  ADD: "ADD",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  DELETE_UPDATE: "DELETE_UPDATE",
  PROCESS: "PROCESS",
};

export const NOTIFICATION_COMPONENT_TYPE = {
  UPLOAD: "UPLOAD",
  DASHBOARD: "DASHBOARD",
  TAB: "TAB",
  EDIT: "EDIT",
  UPLOAD_DASHBOARD: "UPLOAD_DASHBOARD",
  LOADER: "LOADER",
};

export const NOTIFICATION_COMPONENT_SUB_TYPE = {
  DOCUMENT_PERCENTAGE: "DOCUMENT_PERCENTAGE",
  DOCUMENT_AP_STATUS: "DOCUMENT_AP_STATUS",
  DOCUMENT: "DOCUMENT",
  DOCUMENT_UPDATE: "DOCUMENT_UPDATE",
  DOCUMENT_FILTER: "DOCUMENT_FILTER",
  DOCUMENT_FILTER_MAPPING: "DOCUMENT_FILTER_MAPPING",
  DOCUMENT_FIELD: "DOCUMENT_FIELD",
  DOCUMENT_PARENT: "DOCUMENT_PARENT",
  DOCUMENT_PARENT_CHILDREN: "DOCUMENT_PARENT_CHILDREN",
  DOCUMENT_LEASE_STATUS: "DOCUMENT_LEASE_STATUS",
  DOCUMENT_NOTES: "DOCUMENT_NOTES",
  DOCUMENT_TRADE: "DOCUMENT_TRADE_AREA",
  DOCUMENT_DELETE_STATUS: "DOCUMENT_DELETE_STATUS",
  DOCUMENT_AUTHOR: "DOCUMENT_AUTHOR",
  DOCUMENT_MARKET: "DOCUMENT_MARKET",
  DOCUEMENT_READ_STATUS: "DOCUEMENT_READ_STATUS",
  DOCUMENT_PINNED_STATUS: "DOCUMENT_PINNED_STATUS",
  DOCUMENT_PARENT_CHILDREN_ORDER: "DOCUMENT_PARENT_CHILDREN_ORDER",
  DOCUMENT_PARENT_CHILDREN_GROUP: "DOCUMENT_PARENT_CHILDREN_GROUP",
  DOCUMENT_ORDER: "DOCUMENT_ORDER",
  STARTED: "STARTED",
  COMPLETED: "COMPLETED",
};

export const SETTINGS_TAB_NAME = {
  GENERAL: "General Settings",
  // COLUMNS: "Column Labels / Order",
  // ROWS: "Rows Sort Order",
  // TAB: "Tab Name / Aliases",
  MARKET: "Market",
};

export const FILTER_SETTINGS_STATE = {
  FILTER_RENAME: "Filter Rename",
  FILTER_SETTINGS: "Filter Settings",
};

export const TAB_ACTIONS = {
  DELETE: "Delete Filter",
  SETTINGS: "Edit Filter",
  RENAME: "Rename Filter",
  MOVE_LEFT: "Move Left",
  MOVE_RIGHT: "Move Right",
  SET_DEFAULT: "Set Default",
  EXPORT: "Export",
};

export const SETTINGS_TAB_HEADER_SUB_TEXT = {
  MARKET: "(Double-click to rename Market)",
};

export const MENU_TYPE = {
  TABLE: "TABLE",
  TAB: "TAB",
};

export const MENU_SUB_TYPE = {
  MARKET: "MARKET",
  AUTHOR: "AUTHOR",
  STATUS: "STATUS",
  HEADER_STATUS_FILTER: "HEADER_STATUS_FILTER",
  TAB_ACTION: "TAB_ACTION",
  TAB_VIEW: "TAB_VIEW",
  STATUS_HEADER: "STATUS_HEADER",
  CELL: "CELL",
  NOTES: "NOTES",
  CONTEXT_MENU: "CONTEXT_MENU",
};

export const CSS_CUSTOM_VARIABLES = {
  BASE_FONT_SIZE: "--custom-font-size", // dont include
  BASE_FONT_SIZE_IMPACTED: "--custom-user-impacted-font-size",
  PAGE_HEADER_HEIGHT: "--custom-page-header-height", // dont include in param update
  PAGE_HEADER_BORDER_THICKNESS: "--custom-page-header-border-thickness",
  PAGE_HEADER_PADDING: "--custom-page-header-padding",
  PAGE_HEADER_UPLOAD_BUTTON_FONT_SIZE:
    "--custom-page-header-upload-button-font-size",
};

export const UPLOADER_POPUP_COLUMN_NAMES = {
  ID: "ID",
  STATUS: "STATUS",
  DOCUMENT_NAME: "DOCUMENT_NAME",
  AUTHOR: "AUTHOR",
  UPLOADED: "UPLOADED",
  PERCENTAGE_COMPLETED: "PERCENTAGE_COMPLETED",
};

export const BACKEND_AP_STATUS = {
  UPLOADING: "UPLOADING",
  IN_PROGRESS: "IN_PROGRESS",
  AP_COMPLETE: "AP_COMPLETE",
  ABSTRACTING: "ABSTRACTING",
  CONVERTING: "CONVERTING",
  UPLOADED: "UPLOADED",
  HIGHLIGHTING: "HIGHLIGHTING",
  ERROR: "ERROR",
};

export const AP_STATUS = {
  NONE: "NONE",
  UPLOADING: "UPLOADING",
  IN_PROGRESS: "IN_PROGRESS",
  ABSTRACTING: "ABSTRACTING",
  DONE: "DONE",
  ERROR: "ERROR",
};

export const LEASE_STATUS = {
  DUMMY: "DUMMY",
  NONE: "NONE",
  ACTIVE: "ACTIVE",
  IN_LEASE: "IN_LEASE",
  DEAD_DEAL: "DEAD_DEAL",
};

export const AUTHOR = {
  SELECT_AUTHOR: "SELECT AUTHOR",
  TENANT: "TENANT",
  LANDLORD: "LANDLORD",
};

export const RESOLUTION_TYPE = {
  NONE: "NONE",
  HD_LOW: "HD_LOW",
  HD_MEDIUM_1: "HD_MEDIUM_1",
  HD_MEDIUM_2: "HD_MEDIUM_2",
  HD: "HD",
  FULL_HD: "FULL_HD",
  QHD: "QHD",
};

export const TABLE_TYPE = {
  ALL: "ALL",
  LEFT_ACTIONS: "LEFT ACTIONS",
  CONTENT: "CONTENT",
  RIGHT_ACTIONS: "RIGHT ACTIONS",
};

export const IMAGE_TYPE = {
  USER: "USER",
  STATUS: "STATUS",
  ROW_DRAG: "ROW_DRAG",
  COLUMN_PIN: "COLUMN_PIN",
  SETTINGS: "SETTINGS",
  NOTES: "NOTES",
  CHILDREN: "CHILDREN",
  UPLOAD: "UPLOAD_DIALOG_LOGO",
  DASHBOARD: "DASHBOARD_LOGO",
};

export const IMAGE_SUB_TYPE = {
  SHOW: "SHOW",
  HIDE: "HIDE",
  ROW_GROUP: "ROW_GROUP",
  ROW_SORT: "ROW_SORT",
  STATUS_HEADER: "STATUS_HEADER",
  STATUS_LOIs_WORKING: "LOIs_WORKING",
  STATUS_IN_PROGRESS: "IN_PROGRESS",
  STATUS_COMPLETED: "COMPLETED",
};

export const RESOLUTION_TYPE_DIMENSION = {
  HD: {
    width: 1366,
    height: 768,
    rowHeightREM: "10rem",
    headerHeight: 48,
    inActiveRowHeight: 48,
    activeRowHeight: 250,
    underHeaderHeight: 5.33,
    uploaderRowHeight: 37.5,
    uploaderHeaderRowHeight: 60,
    padding: 10,
    fontSize: 14.5,
    lineHeight: 20.5,
    headerLineHeight: 17.545,
    headerGap: 12.532,
    textGap: 10.672,
    scroll_bar: {
      vertical: {
        width: "0.2668rem",
        height: "-1",
        lpadding: "0.5337rem",
        rpadding: "0.5337rem",
        tpadding: "0.3337rem",
        bpadding: "0.3337rem",
        widthPX: 4.269,
        heightPX: -1,
        lpaddingPX: 8.54,
        rpaddingPX: 8.54,
        tpaddingPX: 5.34,
        bpaddingPX: 5.34,
      },
      horizontal: {
        width: "-1",
        height: "0.2668rem",
        lpadding: "0rem",
        rpadding: "0rem",
        tpadding: "0.5337rem",
        bpadding: "0.5337rem",
        widthPX: -1,
        heightPX: 4.269,
        lpaddingPX: 0,
        rpaddingPX: 0,
        tpaddingPX: 8.54,
        bpaddingPX: 8.54,
      },
    },
    image_dimension: {
      STATUS: {
        width: "1.17488rem",
        height: "1.5665rem",
        widthPX: 18.798,
        heightPX: 25.064,
        headerImage: StatusHeader_1366,
        loisImage: LOIsWorking_1366,
        inProgressImage: "",
        completedImage: Completed_1366,
      },
      USER: {
        width: "2.001rem",
        height: "2.001rem",
        widthPX: 44, //32.016,
        heightPX: 44, //32.016,
        image: UserIcon_New,
      },
      UPLOAD_DIALOG_LOGO: {
        width: "7.4375rem",
        height: "1.0506rem",
        widthPX: 119,
        heightPX: 16.81,
        image: UploadLogo_1366,
      },
      DASHBOARD_LOGO: {
        width: "11.8985rem",
        height: "1.6675rem",
        widthPX: 190.376,
        heightPX: 26.68,
        image: DashboardLogo_1366,
      },
      COLUMN_PIN: {
        width: "0.45894rem",
        height: "0.77531rem",
        widthPX: 7.343,
        heightPX: 12.405,
        pinnedImage: ColumnPin_1366,
        unpinnedImage: ColumnUnPin_1280,
      },
      ROW_DRAG: {
        width: "0.7245rem",
        height: "0.89981rem",
        widthPX: 11.592,
        heightPX: 14.397,
        sortImage: RowSortIcon,
        groupImage: RowDragIcon,
      },
      SETTINGS: {
        width: "1.56631rem",
        height: "1.656rem",
        widthPX: 25.06,
        heightPX: 26.5,
        image: Settings_1366,
      },
      NOTES: {
        width: "0.73369rem",
        height: "0.44019rem",
        widthPX: 16,
        heightPX: 18,
        show: ShowNotes,
        hide: HideNotes_1366,
      },
      CHILDREN: {
        width: "0.6669rem",
        height: "0.8669rem",
        widthPX: 10.67,
        heightPX: 13.87,
        show: ShowChildren_1366,
        hide: HideChildren_1366,
      },
      TAB_ADD: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 12,
        heightPX: 12,
        image: NewTab,
      },
      TABS_VIEW: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 12,
        heightPX: 12,
        image: TabSelection,
      },
      TAB_ACTION: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 14,
        heightPX: 18,
        show: TabShow,
        hide: TabHide,
      },
      TAB_NAV_LEFT: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 12,
        heightPX: 15,
        show: Tab_Active_Left_1366,
        hide: Tab_Inactive_Left_1366,
      },
      TAB_NAV_RIGHT: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 12,
        heightPX: 15,
        show: Tab_Active_Right_1366,
        hide: Tab_Inactive_Right_1366,
      },
    },
    columns_dimension: {
      PIN: {
        width: "1.6675rem",
        widthPX: 22 , //26.68,
      },
      AUTHOR: {
        width: "5.00244rem",
        widthPX: 115, //100,
      },
      STATUS: {
        width: "2.668rem",
        widthPX: 135, //90,
      },
      MARKET: {
        width: "6.33644rem",
        widthPX: 140,
      },
      LOI_DATE: {
        width: "11.00538",
        widthPX: 210,
      },
      STATE: {
        width: "11.00538",
        widthPX: 120,
      },
      SUITE: {
        width: "11.00538",
        widthPX: 115,
      },
      NOTES: {
        width: "10.33838rem",
        widthPX: 199, //165.414,
      },
      NOTES_HIDDEN: {
        width: "0.5rem",
        widthPX: 38, //16,
      },
      STATUS_HIDDEN: {
        width: "1rem",
        widthPX: 38, //32,
      },
      CHILDREN: {
        width: "1.6675rem",
        widthPX: 30, //26.68,
      },
      OTHERS: {
        width: "11.00538",
        widthPX: 200, //176.086,
      },
      UPLOAD_STATUS: {
        width: "6rem",
        widthPX: 123,
      },
      UPLOAD_DOCUMENT_NAME: {
        width: "20.125rem",
        widthPX: 620,
      },
      UPLOAD_UPLOADED: {
        width: "6.375rem",
        widthPX: 145,
      },
    },
  },
};

export const CUSTOM_COLUMN_TITLE = {
  PIN: "Pin",
  AUTHOR: "Author",
  STATUS: "Status",
  MARKET: "Market",
  LOI_DATE: "LOI Date",
  NOTES: "Deal Notes and Next Steps",
  NOTES_HIDDEN: "",
  STATUS_HIDDEN: "",
  CHILDREN: "Children",
};

export const CUSTOM_COLUMN_KEY = {
  PIN: "pin",
  AUTHOR: "000a",
  STATUS: "status",
  MARKET: "market",
  LOI_DATE: "loi-date",
  LOI_DATE_KEY: "008b",
  STATE: "003m",
  CITY: "003r",
  TENANT_ENTITY: "001a",
  TENANT: "001d",
  LANDLORD_ENTITY: "002a",
  PROJECT: "003a",
  ADDRESS: "003f",
  SUITE: "003d",
  SIZE: "003b",
  BASE_RENT: "014d",
  TIA: "013a",
  TERM: "004i",
  OPTIONS: "018g",
  NNNN_ESTIMATE: "085c",
  RET: "015l",
  CAM: "027j",
  UNCONTROLLED_CAM: "015m",
  CONTROLLED_CAM: "015n",
  INS: "019d",
  DELIVERY_DATE: "057a",
  RENT_ABATEMENT: "014l",
  PERCENTAGE_RENT: "087a",
  NOTES: "notes",
  TRADE_AREA: "trade",
  DOCUMENT_NAME: "document",
  NOTES_HIDDEN: "notes-hidden",
  STATUS_HIDDEN: "status-hidden",
  CHILDREN: "children",
};

export const CUSTOM_COLUMNS = {
  PIN: {
    id: -4,
    title: CUSTOM_COLUMN_TITLE.PIN,
    key: CUSTOM_COLUMN_KEY.PIN,
    order: -4,
    isMultiLine: false,
  },
  LOI_DATE: {
    id: -3,
    title: CUSTOM_COLUMN_TITLE.LOI_DATE,
    key: CUSTOM_COLUMN_KEY.LOI_DATE,
    order: -3,
    isMultiLine: false,
  },
  AUTHOR: {
    id: -2,
    title: CUSTOM_COLUMN_TITLE.AUTHOR,
    key: CUSTOM_COLUMN_KEY.AUTHOR,
    order: -2,
    isMultiLine: false,
  },
  STATUS: {
    id: -1,
    title: CUSTOM_COLUMN_TITLE.STATUS,
    key: CUSTOM_COLUMN_KEY.STATUS,
    order: -1,
    isMultiLine: false,
  },
  MARKET: {
    id: 0,
    title: CUSTOM_COLUMN_TITLE.MARKET,
    key: CUSTOM_COLUMN_KEY.MARKET,
    order: 0,
    isMultiLine: false,
  },
  NOTES: {
    id: 100,
    title: CUSTOM_COLUMN_TITLE.NOTES,
    key: CUSTOM_COLUMN_KEY.NOTES,
    order: 100,
    isMultiLine: true,
  },
  NOTES_HIDDEN: {
    id: 101,
    title: CUSTOM_COLUMN_TITLE.NOTES_HIDDEN,
    key: CUSTOM_COLUMN_KEY.NOTES_HIDDEN,
    order: 101,
    isMultiLine: true,
  },
  STATUS_HIDDEN: {
    id: 143,
    title: CUSTOM_COLUMN_TITLE.STATUS_HIDDEN,
    key: CUSTOM_COLUMN_KEY.STATUS_HIDDEN,
    order: -4,
    isMultiLine: true,
  },
  CHILDREN: {
    id: 102,
    title: CUSTOM_COLUMN_TITLE.CHILDREN,
    key: CUSTOM_COLUMN_KEY.CHILDREN,
    order: 102,
    isMultiLine: true,
  },
};

export const UPLOAD_HEADER = {
  STATUS: "Status",
  DOCUMENT_NAME: "Document Name",
  UPLOADED: "Uploaded",
};

export const DUMMY_ROWS = _.range(150).map((r) => {
  return {
    id: uniqueId(),
    name: "",
    status: LEASE_STATUS.DUMMY,
    notes: "",
    market: "",
    author: "",
    values: getBlankDocumentData(28),
  } as DocumentEntity;
});

export const SLIDER_FONT_SIZE_HEADERS = [
  {
    label: "very small",
    style: {
      color: "#eee",
    },
  },
  {
    label: "small",
    style: {
      color: "#eee",
    },
  },
  {
    label: "small medium",
    style: {
      color: "#eee",
      marginBottom: "5px",
    },
  },
  {
    label: "medium",
    style: {
      color: "#eee",
    },
  },
  {
    label: "medium large",
    style: {
      color: "#eee",
      paddingBottom: "5px",
    },
  },
  {
    label: "large",
    style: {
      color: "#eee",
    },
  },
  {
    label: "very large",
    style: {
      color: "#eee",
      paddingBottom: "5px",
    },
  },
];

export const SLIDER_LINE_HEIGHT_HEADERS = [
  {
    label: "none",
    style: {
      color: "#eee",
    },
  },
  {
    label: "medium",
    style: {
      color: "#eee",
    },
  },
  {
    label: "loose",
    style: {
      color: "#eee",
    },
  },
];

export const TAB_FILTER_HEADERS = [
  { name: "Tenant Entity", code: "tenantEntity", fieldCode: "001a" },
  { name: "Tenant", code: "tenant", fieldCode: "001d" },
  { name: "City", code: "city", fieldCode: "003r" },
  { name: "Landlord Entity", code: "landlordEntity", fieldCode: "002a" },
  { name: "Project", code: "project", fieldCode: "003a" },
  { name: "Street Address", code: "streetAddress", fieldCode: "003f" },
  { name: "State", code: "state", fieldCode: "003m" },
];

export const STATE_LIST = [
  { isSelected: false, text: "Alabama", code: "AL" },
  { isSelected: false, text: "Alaska", code: "AK" },
  { isSelected: false, text: "Arizona", code: "AZ" },
  { isSelected: false, text: "Arkansas", code: "AR" },
  { isSelected: false, text: "California", code: "CA" },
  { isSelected: false, text: "Colorado", code: "CO" },
  { isSelected: false, text: "Connecticut", code: "CT" },
  { isSelected: false, text: "Delaware", code: "DE" },
  { isSelected: false, text: "District of Columbia", code: "DC" },
  { isSelected: false, text: "Florida", code: "FL" },
  { isSelected: false, text: "Georgia", code: "GA" },
  { isSelected: false, text: "Hawaii", code: "HI" },
  { isSelected: false, text: "Idaho", code: "ID" },
  { isSelected: false, text: "Illinois", code: "IL" },
  { isSelected: false, text: "Indiana", code: "IN" },
  { isSelected: false, text: "Iowa", code: "IA" },
  { isSelected: false, text: "Kansas", code: "KS" },
  { isSelected: false, text: "Kentucky", code: "KY" },
  { isSelected: false, text: "Louisiana", code: "LA" },
  { isSelected: false, text: "Maine", code: "ME" },
  { isSelected: false, text: "Maryland", code: "MD" },
  { isSelected: false, text: "Massachusetts", code: "MA" },
  { isSelected: false, text: "Michigan", code: "MI" },
  { isSelected: false, text: "Minnesota", code: "MN" },
  { isSelected: false, text: "Mississippi", code: "MS" },
  { isSelected: false, text: "Missouri", code: "MO" },
  { isSelected: false, text: "Montana", code: "MT" },
  { isSelected: false, text: "Nebraska", code: "NE" },
  { isSelected: false, text: "Nevada", code: "NV" },
  { isSelected: false, text: "New Hampshire", code: "NH" },
  { isSelected: false, text: "New Jersey", code: "NJ" },
  { isSelected: false, text: "New Mexico", code: "NM" },
  { isSelected: false, text: "New York", code: "NY" },
  { isSelected: false, text: "North Carolina", code: "NC" },
  { isSelected: false, text: "North Dakota", code: "ND" },
  { isSelected: false, text: "Ohio", code: "OH" },
  { isSelected: false, text: "Oklahoma", code: "OK" },
  { isSelected: false, text: "Oregon", code: "OR" },
  { isSelected: false, text: "Pennsylvania", code: "PA" },
  { isSelected: false, text: "Puerto Rico", code: "PR" },
  { isSelected: false, text: "Rhode Island", code: "RI" },
  { isSelected: false, text: "South Carolina", code: "SC" },
  { isSelected: false, text: "South Dakota", code: "SD" },
  { isSelected: false, text: "Tennessee", code: "TN" },
  { isSelected: false, text: "Texas", code: "TX" },
  { isSelected: false, text: "Utah", code: "UT" },
  { isSelected: false, text: "Vermont", code: "VT" },
  { isSelected: false, text: "Virginia", code: "VA" },
  { isSelected: false, text: "Washington", code: "WA" },
  { isSelected: false, text: "West Virginia", code: "WV" },
  { isSelected: false, text: "Wisconsin", code: "WI" },
  { isSelected: false, text: "Wyoming", code: "WY" },
];
