import React, { useEffect, useState } from 'react';
import { setName } from '../../redux/reducers/documentSlice';
import { uploadDocument } from '../../redux/thunks';
import { selectFileInProgressCount, selectFileUploadCount, setFileInProcessCount, setUploader } from '../../redux/reducers/popupSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

const FileDropContainer: React.FC = () => {
  const [isDragOver, setIsDragOver] = useState(false);
  const dispatch = useAppDispatch();
  const fileUploadCount = useAppSelector(selectFileUploadCount);
  useEffect(() => {
    const handleDrop = (event: DragEvent) => {
      const fileInProgressCount = Number(localStorage.getItem('fileInProgressCount') || 0);
      dispatch(setFileInProcessCount(fileInProgressCount));
      event.preventDefault();
      setIsDragOver(false);
      console.log('Upload Count -- ' + fileUploadCount + '--ProgressCount--' + fileInProgressCount);
      if (!(fileUploadCount < 10 && fileInProgressCount < 10)) {
        console.log('File upload reached max limit');
        return;
      }
      console.log('File Uploading ,,...' + fileInProgressCount);
      const files = (event.dataTransfer?.files as FileList) || [];
      const allowedFileTypes = ['.docx', '.doc', '.pdf'];
      // Handle the dropped files
      const fileToBeUploaded = files[0];
      if (fileToBeUploaded?.name === undefined) {
        return;
      }
      const fileExtension = fileToBeUploaded?.name.split('.').pop()?.toLowerCase();
      if (!(fileExtension && allowedFileTypes.includes(`.${fileExtension}`))) {
        return;
      }
      dispatch(setName(fileToBeUploaded.name));
      dispatch(uploadDocument(fileToBeUploaded)).then((d) => {
        dispatch(setUploader());
      });
    };

    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();
      setIsDragOver(true);
    };

    const handleDragLeave = () => {
      setIsDragOver(false);
    };

    // Add event listeners when the component mounts
    document.addEventListener('drop', handleDrop);
    document.addEventListener('dragover', handleDragOver);
    document.addEventListener('dragleave', handleDragLeave);

    // Remove event listeners when the component unmounts
    return () => {
      document.removeEventListener('drop', handleDrop);
      document.removeEventListener('dragover', handleDragOver);
      document.removeEventListener('dragleave', handleDragLeave);
    };
  }, []);

  return (
    <div
      style={{
        width: '100vw',
        height: '100%',
        backgroundColor: isDragOver ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
        display: isDragOver ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <p>Drag LOI into the Table</p>
    </div>
  );
};

export default FileDropContainer;
