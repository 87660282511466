import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { tabFilterState } from "../../common/types/SliceTypes";
import { RootState } from "../store";
import { AXIOS } from "../../api/axios";
import {
  FilterEntity,
  FilterFieldChoiceEntity,
} from "../../common/types/EntityTypes";
import { TAB_FILTER_HEADERS } from "../../common/constants";
import _ from "lodash";

const initialState: tabFilterState = {
  selectedTabFilterInfo: {} as FilterEntity,
  selectedFilterChoices: [],
  isTabFilterDirty: false as boolean,
  filterErrorMessage: "",
  filterDialogState: "",
  readOnlyFilterId: "",
  sharedFilterState: {
    id: '',
    userExternalId: '',
    filterExternalId: '',
    enabled: false,
    emailIds: []
  },
  filterChoiceToBeEdited: -1,
};

export const tabFilterSlice = createSlice({
  name: "tabFilter",
  initialState: initialState,
  reducers: {
    setSelectedFilterSharedState: (state, value) => {
      return {
        ...state,
        sharedFilterState: value.payload,
      };
    },
    setSelectedFilterSharedEnabledState: (state, value) => {
      let filteredState = state.sharedFilterState;
      if (filteredState) filteredState.enabled = true;
      return {
        ...state,
        sharedFilterState: filteredState,
      };
    },
    setSelectedFilterSharedDisabledState: (state, value) => {
      let filteredState = state.sharedFilterState;
      if (filteredState) filteredState.enabled = false;
      return {
        ...state,
        sharedFilterState: filteredState,
      };
    },
    setFilterChoiceToBeEdited: (state, value) => {
      return {
        ...state,
        filterChoiceToBeEdited: value?.payload,
      }
    },
    setIsTabFilterDirty: (state, value) => {
      return {
        ...state,
        isTabFilterDirty: value.payload,
      };
    },
    setFilterDialogState: (state, value) => {
      return {
        ...state,
        filterDialogState: value.payload,
      };
    },
    setSelectedTabFilterInfo: (state, value) => {
      return {
        ...state,
        selectedTabFilterInfo: value.payload,
      };
    },
    setFilterErrorMessage: (state, value) => {
      return {
        ...state,
        filterErrorMessage: value.payload,
      };
    },
    setReadOnlyFilterId: (state, value) => {
      return {
        ...state,
        readOnlyFilterId: value.payload,
      };
    },
    initializeFilterChoices: (state, value) => {
      let filterChoices: FilterFieldChoiceEntity[] = [];
      TAB_FILTER_HEADERS.forEach((field) => {
        filterChoices.push({
          fieldCode: field.fieldCode,
          has: [],
          hasNot: [],
        });
      });
      return {
        ...state,
        selectedFilterChoices: filterChoices,
      };
    },
    setUserSelectedFilterChoices: (state, value) => {
      return {
        ...state,
        selectedFilterChoices: state.selectedFilterChoices?.map((field) => {
          const userChoices = value.payload[field.fieldCode]?.[0];
          if (userChoices?.fieldCode) {
            const f = { ...field };
            if (userChoices?.has) {
              f.has = userChoices?.has;
            }
            if (userChoices?.hasNot) {
              f.hasNot = userChoices?.hasNot;
            }
            return f;
          }
          return field;
        }),
      };
    },
    setSelectedFilterChoices: (state, value) => {
      return {
        ...state,
        isTabFilterDirty: true,
        selectedFilterChoices: state.selectedFilterChoices.map((field) => {
          if (field.fieldCode === value.payload?.fieldCode) {
            const f = { ...field };
            if (value.payload?.has) {
              f.has = [...field.has, value.payload?.has];
            }
            if (value.payload?.hasNot) {
              f.hasNot = [...field.hasNot, value.payload?.hasNot];
            }
            return f;
          }
          return field;
        }),
      };
    },
    setEditableFilterChoice: (state, value) => {
      return {
        ...state,
        isTabFilterDirty: true,
        selectedFilterChoices: state.selectedFilterChoices.map((field) => {
          if (field.fieldCode === value.payload?.fieldCode) {
            const f = { ...field };
            if (value.payload?.hasOption == "has") {
              // if (value.payload?.externalId) {
                f.has = field.has.map((g) => {
                  const el = { ...g };
                  if (el.text === value.payload.text)
                    el.isEditable = value.payload?.isEditable;
                  return el;
                });
              // } else {
              //   f.has = field.has.filter((el) => {
              //     if (el.text !== value.payload.text) return el;
              //   });
              // }
            }
            if (value.payload?.hasOption == "hasNot") {
              // if (value.payload?.externalId) {
                f.hasNot = field.hasNot.map((g) => {
                  const el = { ...g };
                  if (el.text === value.payload.text)
                    el.isEditable = value.payload?.isEditable;
                  return el;
                });
              // } else {
              //   f.hasNot = field.hasNot.filter((el) => {
              //     if (el.text !== value.payload.text) return el;
              //   });
              // }
            }
            return f;
          }
          return field;
        }),
      };
    },
    setEditedFilterChoice: (state, value) => {
      return {
        ...state,
        isTabFilterDirty: true,
        selectedFilterChoices: state.selectedFilterChoices.map((field) => {
          if (field.fieldCode === value.payload?.fieldCode) {
            const f = { ...field };
            if (value.payload?.hasOption == "has") {
              // if (value.payload?.externalId) {
                f.has = field.has.map((g) => {
                  const el = { ...g };
                  if (el.text === value.payload.text) {
                    el.text = value.payload.editExactPhrase;
                    el.isEditable = value.payload?.isEditable;
                    el.action = "UPDATE";
                  }
                  return el;
                });
              // } else {
              //   f.has = field.has.filter((el) => {
              //     if (el.text !== value.payload.text) return el;
              //   });
              // }
            }
            if (value.payload?.hasOption == "hasNot") {
              // if (value.payload?.externalId) {
                f.hasNot = field.hasNot.map((g) => {
                  const el = { ...g };
                  if (el.text === value.payload.text) {
                    el.text = value.payload.editExactPhrase;
                    el.isEditable = value.payload?.isEditable;
                    el.action = "UPDATE";
                  }
                  return el;
                });
              // } else {
              //   f.hasNot = field.hasNot.filter((el) => {
              //     if (el.text !== value.payload.text) return el;
              //   });
              // }
            }
            return f;
          }
          return field;
        }),
      };
    },
    removeFilterChoices: (state, value) => {
      return {
        ...state,
        isTabFilterDirty: true,
        selectedFilterChoices: state.selectedFilterChoices.map((field) => {
          if (field.fieldCode === value.payload?.fieldCode) {
            const f = { ...field };
            if (value.payload?.hasOption == "has") {
              if (value.payload?.externalId) {
                f.has = field.has.map((g) => {
                  const el = { ...g };
                  if (el.text === value.payload.text) el.action = "DELETE";
                  return el;
                });
              } else {
                f.has = field.has.filter((el) => {
                  if (el.text !== value.payload.text) return el;
                });
              }
            }
            if (value.payload?.hasOption == "hasNot") {
              if (value.payload?.externalId) {
                f.hasNot = field.hasNot.map((g) => {
                  const el = { ...g };
                  if (el.text === value.payload.text) el.action = "DELETE";
                  return el;
                });
              } else {
                f.hasNot = field.hasNot.filter((el) => {
                  if (el.text !== value.payload.text) return el;
                });
              }
            }
            return f;
          }
          return field;
        }),
      };
    },
    removeAllFilterChoices: (state, value) => {
      return {
        ...state,
        isTabFilterDirty: true,
        selectedFilterChoices: state.selectedFilterChoices.map((data) => {
          let existingData = { ...data };
          if (data.fieldCode === value.payload.fieldCode) {
            return {
              fieldCode: data.fieldCode,
              has:
                existingData.has
                  ?.map((e) => {
                    const g = { ...e };
                    g.action = "DELETE";
                    return g;
                  })
                  .filter((e) => e?.externalId) || [],
              hasNot:
                existingData.hasNot
                  ?.map((e) => {
                    const g = { ...e };
                    g.action = "DELETE";
                    return g;
                  })
                  .filter((e) => e?.externalId) || [],
            };
          } else {
            return existingData;
          }
        }),
      };
    },
  },
});

export const {
  setIsTabFilterDirty,
  setSelectedTabFilterInfo,
  setSelectedFilterChoices,
  initializeFilterChoices,
  setUserSelectedFilterChoices,
  setEditableFilterChoice,
  setEditedFilterChoice,
  removeFilterChoices,
  removeAllFilterChoices,
  setFilterErrorMessage,
  setFilterDialogState,
  setReadOnlyFilterId,
  setSelectedFilterSharedState,
  setFilterChoiceToBeEdited,
  setSelectedFilterSharedEnabledState,
  setSelectedFilterSharedDisabledState,
} = tabFilterSlice.actions;

export const selectSelectedFilterSharedState = (state: RootState) =>
  state.tabFilter.sharedFilterState;

export const selectFilterChoiceToBeEdited = (state: RootState) =>
  state.tabFilter.filterChoiceToBeEdited;

export const selectSelectedFilterSharedIsEnabledState = (state: RootState) =>
  state.tabFilter.sharedFilterState
    ? state.tabFilter.sharedFilterState.enabled
    : false;

export const selectSelectedFilterSharedEmailState = (state: RootState) =>
  state.tabFilter.sharedFilterState
    ? state.tabFilter.sharedFilterState.emailIds
    : "";

export const selectSelectedTabFilterInfo = (state: RootState) =>
  state.tabFilter.selectedTabFilterInfo;

export const selectFilterDialogState = (state: RootState) =>
  state.tabFilter.filterDialogState;

export const selectSelectedFilterChoices = (state: RootState) =>
  state.tabFilter.selectedFilterChoices;

export const selectFilterErrorMessage = (state: RootState) =>
  state.tabFilter.filterErrorMessage;

export const selectIsTabFilterDirty = (state: RootState) =>
  state.tabFilter.isTabFilterDirty;

export const selectReadOnlyFilterId = (state: RootState) =>
  state.tabFilter.readOnlyFilterId;

export default tabFilterSlice.reducer;

export const getTabNames = createAsyncThunk(
  "client/alias/",
  async (tab: string, { getState }) => {
    const state = getState() as RootState;
    let url =
      process.env.REACT_APP_API_BASE_URL + "client/" + state.user.externalId;
    try {
      const response = await AXIOS.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getHistoryForField = createAsyncThunk(
  "values/field/",
  async (fieldCode: string, { getState }) => {
    const state = getState() as RootState;
    let url =
      process.env.REACT_APP_API_BASE_URL +
      "values/field/" +
      fieldCode +
      "/" +
      state.user.externalId;
    try {
      const response = await AXIOS.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getExactPhrases = createAsyncThunk(
  "/filter/condition/",
  async (params: any, { getState }) => {
    const state = getState() as RootState;
    let url =
      process.env.REACT_APP_API_BASE_URL +
      "/filter/condition/" +
      params.filterId +
      "/" +
      state.user.externalId;
    try {
      const response = await AXIOS.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

export const bulkSaveFilterChoices = createAsyncThunk(
  "/filter/condition/",
  async (params: any, { getState }) => {
    const state = getState() as RootState;
    let url =
      process.env.REACT_APP_API_BASE_URL +
      "/filter/condition/" +
      params.filterId +
      "/" +
      state.user.externalId;
    if (params.limitSearchLatest) {
      url = url + "?isLatestSearch=true";
    }
    try {
      const response = await AXIOS.post(url, [...params.filterChoices]);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

export const setDisabledAccessForSharedFilter = createAsyncThunk(
  "/filter/get/hash/",
  async (params: any, { getState }) => {
    let url =
      process.env.REACT_APP_API_BASE_URL +
      "/filter/share/disabled/" +
      params.filterId +
      "/" +
      params.userId;
    try {
      const response = await AXIOS.put(url);
      return response.data;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }
);

export const setEnabledAccessForSharedFilter = createAsyncThunk(
  "/filter/get/hash/",
  async (params: any, { getState }) => {
    let url =
      process.env.REACT_APP_API_BASE_URL +
      "/filter/share/enabled/" +
      params.filterId +
      "/" +
      params.userId;
    try {
      const response = await AXIOS.put(url);
      return response.data;
    } catch (err) {
      return undefined;
    }
  }
);

export const getClientUserForHash = createAsyncThunk(
  "/filter/hash/",
  async (hash: string, { getState }) => {
    let url = process.env.REACT_APP_API_BASE_URL + "/filter/share/" + hash;
    let response;
    try {
      response = await AXIOS.get(url);
      return response.data;
    } catch (err) {
      return response?.data;
    }
  }
);

export const getHashForFilter = createAsyncThunk(
  "/filter/get/hash/",
  async (params: any, { getState }) => {
    let url =
      process.env.REACT_APP_API_BASE_URL +
      "/filter/copy/" +
      params.filterId +
      "/" +
      params.userId;
    try {
      const response = await AXIOS.post(url);
      return response.data;
    } catch (err) {
      return undefined;
    }
  }
);

export const getSharedDetails = createAsyncThunk(
  "/filter/get/hash/",
  async (params: any, { getState }) => {
    let url =
      process.env.REACT_APP_API_BASE_URL +
      "/filter/copy/" +
      params.filterId +
      "/" +
      params.userId;
    try {
      const response = await AXIOS.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }
);

export const getHashAndShareForFilter = createAsyncThunk(
  "/filter/get/share/hash/",
  async (params: any, { getState }) => {
    let url =
      process.env.REACT_APP_API_BASE_URL +
      "/filter/share/" +
      params.filterId +
      "/" +
      params.userId;
    try {
      const response = await AXIOS.post(url, params.emaildIds);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getAllSharedDetails = createAsyncThunk(
  "/filter/get/user/hash/",
  async (params: any, { getState }) => {
    let url =
      process.env.REACT_APP_API_BASE_URL +
      "/filter/share/" +
      params.filterId +
      "/" +
      params.userId;
    try {
      const response = await AXIOS.get(url);
      return response.data;
    } catch (err) {
      return null;
    }
  }
);
