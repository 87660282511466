import "react-tooltip/dist/react-tooltip.css";
import "./tab-filter.scss";
import TabFilterState from "./tab-filter-controls/tf-state";
import TabTenantEntity from "./tab-filter-controls/tf-tenant";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { TAB_FILTER_HEADERS } from "../../../../common/constants";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  bulkSaveFilterChoices,
  getExactPhrases,
  initializeFilterChoices,
  selectFilterErrorMessage,
  selectIsTabFilterDirty,
  selectSelectedFilterChoices,
  selectSelectedTabFilterInfo,
  setFilterDialogState,
  setIsTabFilterDirty,
  setSelectedTabFilterInfo,
  setUserSelectedFilterChoices,
  removeAllFilterChoices,
  setFilterChoiceToBeEdited,
} from "../../../../redux/reducers/tabFilterSlice";
import {
  FilterChoiceEntity,
  FilterFieldChoiceEntity,
} from "../../../../common/types/EntityTypes";
import _ from "lodash";
import { fetchUserClientsDocuments } from "../../../../redux/reducers/documentsDataSlice";
import { selectUserID } from "../../../../redux/reducers/userSlice";
import {
  selectClients,
  updateUserFilterName,
} from "../../../../redux/reducers/metadataSlice";
import OrangeTrashIcon from "../../../../assets/images/dialog/filter/trashIcon_orange.svg";

function TabFilter() {
  const dispatch = useAppDispatch();
  const [hasOption, setHasOption] = useState("has");
  const tabFilterFieldList = TAB_FILTER_HEADERS;
  const [activeFilterNav, setActiveFilterNav] = useState(tabFilterFieldList[0]);
  const selectedTabFilter = useAppSelector(selectSelectedTabFilterInfo);
  const selectedFilterChoices = useAppSelector(selectSelectedFilterChoices);
  const isTabFilterDirty = useAppSelector(selectIsTabFilterDirty);
  const [limitSearchLatest, setLimitSearchLatest] = useState(false);
  const userId = useAppSelector(selectUserID);
  const clients = useAppSelector(selectClients);
  const clientNames = clients
    .map((client) => client.name)
    .filter(
      (clientName) =>
        clientName != "Inbox" && clientName != selectedTabFilter.name
    );
  const filterErrorMessage = useAppSelector(selectFilterErrorMessage);
  const [activeFieldFilters, setActiveFieldFilters] = useState([] as string[]);
  // const inputRef = useRef<HTMLInputElement | null>(null);
  const [newFilterName, setFilterName] = useState(selectedTabFilter.name);
  const [filterNameErrorMessage, setFilterNameErrorMessage] = useState(false);
  const [filterNameExistsErrorMessage, setFilterNameExistsErrorMessage] =
    useState(false);
  const [filterSettingsUnsavedError, setFilterSettingsUnsavedError] = useState(false);
  const [updateHistoryPhrasesFlag, setUpdateHistoryPhrasesFlag] =
    useState(false);
  // const [isEditTabNameEnable, setIsEditTabNameEnable] = useState(false);
  // const [isExactPhraseLoading, setExactPhraseLoading] = useState(false);

  const handleCancel = () => {
    dispatch(setFilterChoiceToBeEdited(-1));
    dispatch(setIsTabFilterDirty(false));
    dispatch(
      getExactPhrases({
        filterId: selectedTabFilter?.id,
      })
    ).then((resp) => {
      dispatch(initializeFilterChoices({}));
      dispatch(setUserSelectedFilterChoices(resp.payload?.data));
      setUpdateHistoryPhrasesFlag((prev) => !prev);
      dispatch(setFilterDialogState(""));
      dispatch(setSelectedTabFilterInfo({}));
    });
  };

  useEffect(() => {
    let activeFields = [] as string[];
    selectedFilterChoices.forEach((e) => {
      if (
        e.has?.filter((p) => p.action !== "DELETE")?.length > 0 ||
        e.hasNot?.filter((p) => p.action !== "DELETE")?.length > 0
      ) {
        activeFields.push(e.fieldCode);
      }
    });
    setActiveFieldFilters(activeFields);
  }, [selectedFilterChoices]);

  useEffect(() => {
    dispatch(
      getExactPhrases({
        filterId: selectedTabFilter?.id,
      }),
    ).then((dbSelectResp) => {
      dispatch(setUserSelectedFilterChoices(dbSelectResp.payload?.data));
    });
  }, [selectedTabFilter]);

  const handleApply = () => {
    if (!newFilterName) {
      setFilterNameErrorMessage(true);
      return;
    } else if (clientNames.includes(newFilterName)) {
      setFilterNameExistsErrorMessage(true);
      return;
    }
    dispatch(
      updateUserFilterName({ ...selectedTabFilter, name: newFilterName })
    ).then((e) => {
      dispatch(setFilterChoiceToBeEdited(-1));
      setFilterNameErrorMessage(false);
      setFilterNameExistsErrorMessage(false);
      if (e.payload) {
        handleSettingsSave();
      }
    });
  };

  // function measureTextWidth(text: string) {
  //   const span = document.createElement("span");
  //   span.style.visibility = "hidden";
  //   span.style.whiteSpace = "pre";
  //   span.style.font = "inherit";
  //   span.textContent = text;
  //   document.body.appendChild(span);
  //   const width = span.offsetWidth * 1.1;
  //   document.body.removeChild(span);
  //   return width;
  // }

  const handleSettingsSave = (option?: string, nav?: any) => {
    let filterChoices = [] as FilterFieldChoiceEntity[];
    selectedFilterChoices.forEach((field) => {
      let has = [] as FilterChoiceEntity[];
      let hasNot = [] as FilterChoiceEntity[];
      field?.has.forEach((phrases) => {
        let cl = {} as FilterChoiceEntity;
        if (phrases?.action === "DELETE"
          || (option === 'Remove All' && activeFilterNav.fieldCode === field.fieldCode)) {
          cl = {
            text: phrases.text,
            action: "DELETE",
            externalId: phrases.externalId,
          };
        } else {
          cl = {
            text: phrases.text,
            action: "UPDATE",
          };
          if (phrases?.externalId) {
            cl["externalId"] = phrases.externalId;
          }
        }
        has.push(cl);
        return cl;
      });
      field?.hasNot.forEach((phrases) => {
        let cl = {} as FilterChoiceEntity;
        if (phrases.action === "DELETE"
          || (option === 'Remove All' && activeFilterNav.fieldCode === field.fieldCode)) {
          cl = {
            text: phrases.text,
            action: "DELETE",
            externalId: phrases.externalId,
          };
        } else {
          cl = {
            text: phrases.text,
            action: "UPDATE",
          };
          if (phrases?.externalId) {
            cl["externalId"] = phrases.externalId;
          }
        }
        hasNot.push(cl);
        return cl;
      });
      filterChoices.push({
        fieldCode: field.fieldCode,
        has,
        hasNot,
      });
      return field;
    });
    const params = {
      fieldCode: activeFilterNav?.fieldCode,
      filterId: selectedTabFilter?.id,
      filterChoices: filterChoices,
      limitSearchLatest: limitSearchLatest,
    };
    dispatch(bulkSaveFilterChoices(params)).then((res) => {
      // dispatch(setSelectedTabFilterInfo({}));
      // if (option === 'Changing Tab') {
      //   setActiveFilterNav(nav);
      // }
      dispatch(setIsTabFilterDirty(false));
      setUpdateHistoryPhrasesFlag((prev) => !prev);
      dispatch(
        fetchUserClientsDocuments({
          clientIds: clients.map((client) => client.id),
          userId: userId.toString(),
        })
      );
    });
  };

  // const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
  //   const newValue = event.target.value;
  //   event.target.style.width = measureTextWidth(event.target.value) + "px";
  //   setFilterName(newValue);
  //   setFilterNameErrorMessage(false);
  //   setFilterNameExistsErrorMessage(false);
  //   if (!isTabFilterDirty) dispatch(setIsTabFilterDirty(true));
  // };

  // const handleTabNameKeyDown = (event: any) => {
  //   if (event.key === "Escape") {
  //     setFilterName(selectedTabFilter.name);
  //     setIsEditTabNameEnable(false);
  //   } else if (event.key === "Enter" && newFilterName !== "") {
  //     setIsEditTabNameEnable(false);
  //   }
  // };

  const handleClearAllFilterChoices = (nav: any) => {
    dispatch(removeAllFilterChoices(nav));
    handleSettingsSave('Remove All', nav);
  };

  useEffect(() => {
    if (selectedTabFilter?.name) {
      dispatch(initializeFilterChoices({}));
    }
  }, [selectedTabFilter]);

  useEffect(() => {
    if(limitSearchLatest) dispatch(setIsTabFilterDirty(true));
  }, [limitSearchLatest]);

  return (
    <div className="tab-filter-container">
      <div className="tf-header">
        <div className="tf-header-text">
          {/* {isEditTabNameEnable ?  */}
          {/* <input
            className='tf-filter-name-input'
            ref={inputRef}
            onChange={(event) => handleChange(event)}
            onKeyDown={(event) => handleTabNameKeyDown(event)}
            type='text'
            maxLength={35}
            value={newFilterName}
            style={{
              height: 'inherit',
              width: measureTextWidth(selectedTabFilter?.name),
            }}
          /> */}
          <span className="tf-filter-name-input">{newFilterName}</span>
          &nbsp;&nbsp;
          {/* : <span className='tf-tab-name-orange' onClick={() => setIsEditTabNameEnable(true)}>{newFilterName}</span>} */}
          <span>Tab Filter</span>
        </div>
        {/* <div className='tf-header-input tf-checkbox'>
          <input
            checked={limitSearchLatest}
            type='checkbox'
            onChange={() => console.log()}
            id={`checkbox-limit`}
            className='tf-state-checkbox custom-checkbox'
          />
          <label
            htmlFor={`checkbox-limit`}
            onClick={() => setLimitSearchLatest(!limitSearchLatest)}>
            Limit search to latest version of LOI
          </label>
        </div> */}
      </div>

      <div className="tf-body">
        <div className="tfb-nav">
          <div className="tfn-nav-link-box">
            {tabFilterFieldList &&
              tabFilterFieldList
                .filter((nav) => activeFieldFilters.includes(nav.fieldCode))
                .concat(
                  tabFilterFieldList.filter(
                    (nav) => !activeFieldFilters.includes(nav.fieldCode)
                  )
                )
                .map((nav, navIdx) => {
                  const isFilterDirty =
                    activeFieldFilters.indexOf(nav.fieldCode) > -1;
                  return (
                    <div
                      className={classNames("nav-link-container")}
                      key={nav.code}
                    >
                      <div
                        className={classNames("tfn-nav-link", {
                          "tfn-nav-link-active":
                            nav.code === activeFilterNav.code,
                          "tfn-nav-link-white": !isFilterDirty,
                          "tfn-nav-link-separator":
                            activeFieldFilters[
                              activeFieldFilters.length - 1
                            ] === nav.fieldCode,
                        })}
                        onClick={() => {
                          // if (isTabFilterDirty) {
                          //   setFilterNameErrorMessage(true);
                          //   return;
                          // }
                          setActiveFilterNav(nav);
                          // handleSettingsSave('Changing Tab', nav);
                        }}
                      >
                        {nav.name}
                      </div>
                      <div className={classNames("nav-link-clear-container")}>
                        {nav.code === activeFilterNav.code && isFilterDirty && (
                          // <div
                          //   key={nav.code + "-clear"}
                          //   onClick={() => handleClearAllFilterChoices(nav)}
                          // >
                            <img
                              key={nav.code + "-clear"}
                              onClick={() => handleClearAllFilterChoices(nav)}
                              src={OrangeTrashIcon}
                              alt="Delete"
                              title="Delete"
                            />
                          // </div>
                        )}
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
        <div className="tfb-content">
          {activeFilterNav.code === "state" && (
            <div className="tfb-content-top-nav">
              <div className="tfbc-top-nav-content">
                <div
                  className={classNames("tfb-btn", {
                    active: hasOption === "has",
                  })}
                  onClick={() => {
                    dispatch(setFilterChoiceToBeEdited(-1));
                    setHasOption("has");
                  }}
                >
                  INCLUDES
                </div>
                <div
                  className={classNames("tfb-btn", {
                    active: hasOption === "hasNot",
                  })}
                  onClick={() => {
                    dispatch(setFilterChoiceToBeEdited(-1));
                    setHasOption("hasNot");
                  }}
                >
                  EXCLUDES
                </div>
              </div>
            </div>
          )}
          {activeFilterNav.code == "state" && (
            <TabFilterState
              activeField={activeFilterNav}
              hasOption={hasOption}
            ></TabFilterState>
          )}
          {activeFilterNav.code !== "state" && (
            <TabTenantEntity
              activeField={activeFilterNav}
              updateHistoryFlag={updateHistoryPhrasesFlag}
            ></TabTenantEntity>
          )}
        </div>
      </div>
      <div className="tf-footer">
        <div className="tf-footer-error">
          {filterErrorMessage && (
            <span>
              The <strong>{activeFilterNav.name}</strong> field has a duplicate
              entry
              <div>
                in the <strong>{filterErrorMessage}</strong> tab.
              </div>
            </span>
          )}
          {filterNameErrorMessage && (
            <span>
              The <strong>Tab Filter Name</strong> field is empty. Please enter
              a valid name.
            </span>
          )}
          {filterNameExistsErrorMessage && (
            <span>
              The Tab Filter Name <strong>{newFilterName}</strong> already
              exists. Please enter a unique name.
            </span>
          )}
          {filterSettingsUnsavedError && (
            <span>
              The Tab Filter Name <strong>{newFilterName}</strong> already
              exists. Please enter a unique name.
            </span>
          )}
        </div>
        {isTabFilterDirty ? (
          <div className="tf-buttons-holder">
            <button
              className="tf-button tf-button-yellow"
              onClick={handleApply}
            >
              <span className="tf-button-text">Apply</span>
            </button>
            <button className="tf-button" onClick={handleCancel}>
              <span className="tf-button-text">Cancel</span>
            </button>
          </div>
        ) : (
          <button
            className="tf-button"
            onClick={() => {
              dispatch(setFilterChoiceToBeEdited(-1));
              dispatch(setFilterDialogState(""));
              dispatch(setSelectedTabFilterInfo({}));
            }}
          >
            <span className="tf-button-text">Close</span>
          </button>
        )}
      </div>
    </div>
  );
}
export default TabFilter;
