import classNames from "classnames";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { FieldValueMetadata } from "../../../../common/types/dashboard/DashboardUITypes";
import { upsertFieldValues } from "../../../../db/fieldDBAction";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  selectFieldInEditMode,
  selectSelectedDocumentId,
  selectTextCopiedFromLHS,
  setFieldInEditMode,
  setTextCopiedFromLHS,
} from "../../../../redux/reducers/dashboardSlice";
import "./EditableValue.scss";
import { selectUserAppFontSize } from "../../../../redux/reducers/settingsSlice";
import { selectCustomLineHeight } from "../../../../redux/reducers/uiSlice";
import { updateDocumentFieldValue } from "../../../../redux/thunks";
import TickIcon from "../../../../assets/images/dialog/settings/blackTick.svg";
import TickIconDisabled from "../../../../assets/images/dashboard/table/SaveTickIcon_Disabled.svg";
import CrossIcon from "../../../../assets/images/dialog/settings/blackCross.svg";
import CrossIconDisabled from "../../../../assets/images/dialog/settings/grayedCross.svg";
import { selectReadOnlyApp } from "../../../../redux/reducers/appSlice";

function EditableValue(props: {
  field: FieldValueMetadata;
  isSelected: boolean;
  selectedField: string;
  docLoadingDone: boolean;
}) {
  const isReadOnly = useAppSelector(selectReadOnlyApp);
  const selectedDocumentId = useAppSelector(selectSelectedDocumentId);
  const dispatch = useAppDispatch();
  const field = { ...props.field };
  const initialValue = field.value; // Original value before any edits
  const [isEditable, setIsEditable] = useState(false);
  const [fieldNewValue, setFieldNewValue] = useState(field.value);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const fieldInEditMode = useAppSelector(selectFieldInEditMode);
  const textCopied = useAppSelector(selectTextCopiedFromLHS);
  const [initCounter, setInitCounter] = useState(0);

  const handleKeyDown = (event: any) => {
    event.target.style.height = "auto";
    event.target.style.height = `${event.target.scrollHeight}px`;
    const newValue = event.target.value.trim();
    if (event.key === "Escape") {
      handleCancel();
    } else if (event.key === "Enter") {
      handleSave(newValue);
    }
  };

  const handleSave = (newValue: string) => {
    if (newValue !== initialValue) {
      upsertFieldValues({
        docId: selectedDocumentId,
        isPushed: false,
        fieldId: field.field,
        newValue: newValue,
        oldValue: initialValue,
        isPersisted: false,
      }).then(() => {
        dispatch(
          updateDocumentFieldValue({
            docId: selectedDocumentId,
            fieldCode: field.field,
            newValue: newValue,
            oldValue: initialValue,
            engineValue: field.engineValue,
          })
        );
      });
      field.prevValue = initialValue;
      field.value = newValue;
    }
    setFieldNewValue(newValue);
    setIsEditable(false);
    dispatch(setFieldInEditMode(""));
  };

  const handleCancel = () => {
    setFieldNewValue(initialValue); // Reset to original value
    setIsEditable(false);
    dispatch(setFieldInEditMode(""));
  };

  const handleDocumentClick: EventListener = (event) => {
    if (
      textareaRef.current &&
      event.target instanceof Node &&
      !textareaRef.current.contains(event.target)
    ) {
      handleSave(fieldNewValue.trim());
    }
  };

  const handleEditMode = (fieldCode: string) => {
    if (!isReadOnly) {
      setIsEditable(true);
      dispatch(setFieldInEditMode(fieldCode));
      setTimeout(() => {
        if (textareaRef.current) {
          textareaRef.current.focus();
          textareaRef.current.select();
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (props.docLoadingDone && initCounter === 0 && props.selectedField === props.field.field) {
      setInitCounter(prev => prev + 1);
      handleEditMode(props.selectedField);
    }
  }, [props.docLoadingDone]);

  const handleReset = () => {
    upsertFieldValues({
      docId: selectedDocumentId,
      isPushed: false,
      fieldId: field.field,
      newValue: field.engineValue,
      oldValue: initialValue,
      isPersisted: false,
    }).then(() => {
      dispatch(
        updateDocumentFieldValue({
          docId: selectedDocumentId,
          fieldCode: field.field,
          newValue: field.engineValue,
          oldValue: initialValue,
          engineValue: field.engineValue,
        })
      );
    });
    setFieldNewValue(field.engineValue);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [fieldNewValue]);

  useEffect(() => {
    if (field.field !== props.selectedField && initialValue !== fieldNewValue) {
      handleSave(fieldNewValue);
    }
  }, [props.selectedField]);

  useEffect(() => {
    if (textCopied !== "" && props.isSelected) {
      setFieldNewValue(textCopied);
      handleSave(textCopied);
      dispatch(setTextCopiedFromLHS(""));
    }
  }, [textCopied]);

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setFieldNewValue(event.target.value);
  };

  const userFontSize = Number(useAppSelector(selectUserAppFontSize).value);
  const userLineHeight = useAppSelector(selectCustomLineHeight);
  const customStyle = {
    cursor: "pointer",
    height: "auto",
    fontStyle: "normal",
    "--custom-text-font-size": userFontSize + "px",
    "--custom-line-height": userLineHeight,
  };

  const hasChanges = fieldNewValue.trim() !== initialValue;

  return (
    <>
      <div className="value-holder">
        <div
          style={customStyle}
          className={classNames("value", {
            hide: isEditable && fieldInEditMode === props.field.field,
            "value-edited": props.field.engineValue !== fieldNewValue,
            "value-selected": props.isSelected,
            "value-not-selected": !props.isSelected,
          })}
          onDoubleClick={() => handleEditMode(props.field.field)}
        >
          {fieldNewValue}
        </div>
        <div
          className={classNames("value-reset", {
            hide: fieldNewValue === props.field.engineValue || isEditable,
          })}
          onClick={() => handleReset()}
        >
          Reset
        </div>
      </div>
      <div
        className={classNames("value-textbox-container", {
          hide: !(isEditable && fieldInEditMode === props.field.field),
        })}
      >
        <textarea
          ref={textareaRef}
          className={classNames("value")}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
          value={fieldNewValue}
        ></textarea>
        <div
          className={classNames("value-save-tick", {
            "value-tick-disabled": !hasChanges,
          })}
          onClick={() => hasChanges && handleSave(fieldNewValue.trim())}
        >
          <img src={hasChanges ? TickIcon : TickIconDisabled} alt="✓" />
        </div>
        <div
          className={classNames("value-save-cross")}
          onClick={handleCancel}
          title="Cancel"
        >
          <img src={CrossIcon} alt="✗" />
        </div>
      </div>
    </>
  );
}
export default EditableValue;