import { CircleSpinnerOverlay } from "react-spinner-overlay";
import { useAppDispatch } from "./redux/hooks";
import Wrapper from "./Wrapper";
import { setReadOnlyApp, setReadOnlyHash } from "./redux/reducers/appSlice";
import {
  getClientUserForHash,
  setReadOnlyFilterId,
} from "./redux/reducers/tabFilterSlice";
import { setUserExternalId } from "./redux/reducers/userSlice";
import { useEffect, useState } from "react";

const AppReadOnly = () => {
  let [isLoading, setIsLoading] = useState(true);
  let [isError, setIsError] = useState(false);
  const dispatch = useAppDispatch();
  dispatch(setReadOnlyApp(true));

  const errorSection = (
    <section className="app-container">
      <div><b>Access Denied. The shared link is no longer valid.</b></div>
    </section>
  );

  useEffect(() => {
    const currentURL = window.location.href;
    const params = currentURL.split("?")[1].split("&")[1].split("=");
    dispatch(setReadOnlyHash(params[1]));

    if (params[0] !== "hash") {
      setIsLoading(false);
      setIsError(true);
    } else {
      dispatch(getClientUserForHash(params[1]))
        .then((resp) => {
          if (resp.payload) {
            dispatch(setUserExternalId(resp.payload.data["userExternalId"]));
            dispatch(
              setReadOnlyFilterId(resp.payload.data["filterExternalId"])
            );
          } else {
            setIsError(true);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <CircleSpinnerOverlay
          loading={isLoading}
          color="#404040"
          zIndex={5000}
        />
      ) : isError ? (
        errorSection
      ) : (
        <Wrapper />
      )}
    </>
  );
};
export default AppReadOnly;
