import { FileUploader } from 'react-drag-drop-files';
import { SUPPORTED_FILE_FORMATS } from '../../common/constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setName } from '../../redux/reducers/documentSlice';
import { selectDashboardLogoMetadata, selectUserIconMetadata } from '../../redux/reducers/uiSlice';
import { uploadDocument } from '../../redux/thunks';
import './header.scss';
import { setSettings, setUploader } from '../../redux/reducers/popupSlice';
import { useAuth0 } from '@auth0/auth0-react';
import { getUpdatedCustomFontSize } from '../../common/util';
import { selectUserRole } from '../../redux/reducers/userSlice';
import { useEffect, useRef, useState } from 'react';
import { selectReadOnlyApp } from '../../redux/reducers/appSlice';
import classNames from 'classnames';
import { bulkDeleteDocument, resetAll } from '../../redux/reducers/documentsStatusSlice';
import DocStatusIcon from '../../assets/images/dashboard/header/docStatusIcon.svg';
import FileUploadIcon from '../../assets/images/dashboard/header/fileUploadIcon.svg';
import SettingsIcon from '../../assets/images/dashboard/header/appSettingsIcon.svg';
import UserLandlordRepIcon from '../../assets/images/dashboard/header/User_Landlord_Rep.svg';
import UserTenantRepIcon from '../../assets/images/dashboard/header/User_Tenant_Rep.svg';
import AppLogo from '../../assets/images/dashboard/header/headerAppLogo.svg';
import UserProfileMenu from '../popup/user-profile/UserProfileMenu';
import { selectUserProfileMenuState, setUserProfileMenuState } from '../../redux/reducers/dashboardSlice';

const Header = () => {
  const dispatch = useAppDispatch();
  const isReadOnly = useAppSelector(selectReadOnlyApp);
  const logoMetadata = useAppSelector(selectDashboardLogoMetadata);
  const userMetadata = useAppSelector(selectUserIconMetadata);
  const { user, logout } = useAuth0();
  const userRole = useAppSelector(selectUserRole) == 'Landlord' ? 'Landlord' : 'Tenant';
  const isUserMenuOpen = useAppSelector(selectUserProfileMenuState);
  const menuRef = useRef<HTMLDivElement>(null);

  const openPopup = () => {
    dispatch(setUploader());
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        dispatch(setUserProfileMenuState(false));
      }
    }
    if (isUserMenuOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isUserMenuOpen]);

  window.addEventListener('storage', function (event) {
    if (event.key === 'logoutEvent') {
      this.localStorage.removeItem('logoutEvent');
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }
  });

  const handleChange = (file: File) => {
    if (file !== undefined) {
      dispatch(setName(file.name));
      dispatch(uploadDocument(file)).then((d) => {
        openPopup();
      });
    }
  };
  const handleProfileMenu = () => {
    dispatch(setUserProfileMenuState(!isUserMenuOpen));
  };
  const customStyle = {
    justifyContent: 'space-between',
    '--custom-CB-fontSize': getUpdatedCustomFontSize('--custom-CB-fontSize'),
    '--custom-upload-btn-fontSize': getUpdatedCustomFontSize('--custom-upload-btn-fontSize'),
    '--custom-user-details-fontSize': getUpdatedCustomFontSize('--custom-user-details-fontSize'),
  };
  return (
    <div className='flex-fit-basis' style={customStyle}>
      <div className='header-logo'>
        <img
          className='cursor-default'
          src={AppLogo}
        />
      </div>
      <div
        id="header-right-tools"
        className={classNames(
          'image-container',
          isReadOnly ? 'visible-hidden' : 'visibilty-not-hiddent',
        )}>
        <div className='header-upload-continer-text'>
          Drag LOI into the Table or choose a file
        </div>
        <FileUploader
          classes='flex'
          types={SUPPORTED_FILE_FORMATS}
          handleChange={handleChange}
          name='file'
          maxSize={200}
          required={true}
          children={
            <div className='header-icons'>
              <img src={FileUploadIcon} alt='File Upload' />
            </div>
          }
        />
        <div className="header-icons" onClick={openPopup}>
          <img src={DocStatusIcon} alt='LOI Status' />
        </div>
        <div className="header-settings-icon">
          <img
            className={classNames(
              isReadOnly ? "visible-hidden" : "visibilty-not-hiddent",
              "img-no-shirnk"
            )}
            src={SettingsIcon}
            onClick={() => dispatch(setSettings(true))}
          />
        </div>
        <div
          className={classNames(
            isReadOnly ? 'visible-hidden' : 'visibilty-not-hiddent',
            'actions-container',
          )}
          onClick={handleProfileMenu}
          ref={menuRef}>
          <div className='flex user-container flex-align-center'>
            <img
              className='user-profile-image'
              src={userRole === 'Landlord' ? UserLandlordRepIcon : UserTenantRepIcon}
            />
          </div>
        </div>
      </div>

      {isUserMenuOpen && (
        <div className='user-profile-menu'>
          <UserProfileMenu></UserProfileMenu>
        </div>
      )}
    </div>
  );
};

export default Header;
