import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "../../common/types/SliceTypes";
import { RootState } from "../store";

const initial: AppState = {
  network: navigator.onLine,
  lastPing: performance.now(),
  binded: false,
  syncRequired: false,
  initialized: false,
  isReadOnly: false,
  isInvalidHash: false,
  readOnlyHash: "",
  loader: false
};

export const AppSlice = createSlice({
  name: "app",
  initialState: initial,
  reducers: {
    setInitialized: (state, value) => {
      return {
        ...state,
        initialized: value.payload,
      };
    },
    setPing: (state) => {
      return {
        ...state,
        lastPing: performance.now(),
      };
    },
    setBinding: (state, value) => {
      return {
        ...state,
        binded: value.payload,
      };
    },
    setNetwork: (state, value) => {
      return {
        ...state,
        network: value.payload,
      };
    },
    setReadOnlyApp: (state, value) => {
      return {
        ...state,
        isReadOnly: value.payload,
      };
    },
    setReadOnlyHash: (state, value) => {
      return {
        ...state,
        readOnlyHash: value.payload,
      };
    },
    setIsInvalidHash: (state, value) => {
      return {
        ...state,
        isInvalidHash: value.payload,
      };
    },
    setLoader: (state, value) => {
      return {
        ...state,
        loader: value.payload,
      };
    },
  },
});

export const {
  setPing,
  setBinding,
  setNetwork,
  setInitialized,
  setReadOnlyApp,
  setReadOnlyHash,
  setIsInvalidHash,
  setLoader
} = AppSlice.actions;

export const selectLoader = (state:RootState) => state.app.loader;

export const selectLastPing = (state: RootState) => state.app.lastPing;

export const selectNetwork = (state: RootState) => state.app.network;

export const selectReadOnlyApp = (state: RootState) => state.app.isReadOnly;

export const selectReadOnlyHash = (state: RootState) => state.app.readOnlyHash;
export const selectInvalidReadOnly = (state: RootState) =>
  state.app.isInvalidHash;

export const selectIsAppInitialized = (state: RootState) =>
  state.app.initialized;

export default AppSlice.reducer;
