import React, { useState } from 'react';
import { User, RefreshCw, FileText, LogOut, Settings, Mail, X } from 'lucide-react';
import './UserProfileMenu.scss';
import { selectUserRole } from '../../../redux/reducers/userSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { bulkDeleteDocument, resetAll } from '../../../redux/reducers/documentsStatusSlice';
import { setUserProfileMenuState } from '../../../redux/reducers/dashboardSlice';

type UserRole = 'tenant' | 'landlord' | 'owner' | 'retailer';

const userTypes: Record<UserRole, { label: string; color: string }> = {
  tenant: { label: 'Tenant Representative', color: 'green' },
  landlord: { label: 'Landlord Representative', color: 'blue' },
  owner: { label: 'Property Owner', color: 'yellow' },
  retailer: { label: 'Retailer', color: 'purple' },
};

export default function UserProfileMenu() {
  const { user, logout } = useAuth0();
  const userRole = useAppSelector(selectUserRole) == 'Landlord' ? 'landlord' : 'tenant';
  const userRoles: UserRole[] = ['tenant', 'landlord', 'owner', 'retailer'];
  const [currentRole, setCurrentRole] = useState<UserRole>(userRole);
  const userType = userTypes[userRole];
  const dispatch = useAppDispatch();

  const logoutWithRedirect = () => {
    localStorage.setItem('logoutEvent', String(Date.now()));
    dispatch(setUserProfileMenuState(false));
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <div className='modal-overlay'>
      <div className='modal-container'>
        <div className={`modal-header ${userType.color}-bg-light`}>
          <div className='modal-header-content'>
            <div className='profile-section'>
              <div className={`profile-icon-large ${userType.color}-bg`}>
                <User className='icon-large' />
              </div>
              <div className='user-details-holder'>
                <div className='user-name'>{user?.name}</div>
                <div className='user-email'>
                  <Mail className='icon-small' />
                  <span>{user?.email}</span>
                </div>
                <div className={`user-role ${userType.color}-text`}>{userType.label}</div>
              </div>
            </div>
          </div>
        </div>

        <div className='modal-body'>
          <div onClick={() => dispatch(resetAll())}>
            <MenuItem icon={<RefreshCw className='icon' />} label='Reset All' color='gray' />
          </div>
          <div onClick={() => dispatch(bulkDeleteDocument())}>
            <MenuItem icon={<FileText className='icon' />} label='Reset Docs' color='gray' />
          </div>
          <div className='divider' />
          <div className='logout-menu' onClick={logoutWithRedirect}>
            <MenuItem icon={<LogOut className='icon-red' />} label='Logout' color='red' />
          </div>
        </div>
      </div>
    </div>
  );
}

interface MenuItemProps {
  icon: JSX.Element;
  label: string;
  color: string;
}

function MenuItem({ icon, label, color }: MenuItemProps) {
  return (
    <button className={`menu-item ${color}-hover`}>
      <div className={`menu-icon ${color}-bg-light`}>{icon}</div>
      <span className={`menu-label ${color}-text`}>{label}</span>
    </button>
  );
}
