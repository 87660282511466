import {
  CellClickedEvent,
  CellDoubleClickedEvent,
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  GetRowIdFunc,
  GetRowIdParams,
  IRowNode,
  RowDragEndEvent,
  RowDragMoveEvent,
  RowHeightParams,
  SuppressKeyboardEventParams,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CUSTOM_COLUMN_KEY,
  LEASE_STATUS,
  NONE_MENU_ID,
} from "../../../common/constants";
import { CustomDashboardParams } from "../../../common/types/dashboard/DashboardUITypes";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import _, { after } from "lodash";
import { DocumentEntity, FieldEntity } from "../../../common/types/EntityTypes";
import { updateDocumentReadStatus } from "../../../db/documentDBAction";
import {
  fetchDocumentHTML,
  rowCollapseUpdate,
  rowExpandUpdate,
  selectCurrentTab,
  selectIsDocumentDeleted,
  selectPrevDocumentExpandedId,
  selectSelectedDocumentExpandedId,
  selectSelectedDocumentId,
  setDocumentDeleted,
  setDocumentExpanded,
  setSelectedDocument,
  updateFieldResize,
  updateRowReadStatus,
} from "../../../redux/reducers/dashboardSlice";
import {
  dragLeaseMapping,
  markDocumentRead,
  selectRows,
  setDocumentRowAsExpanded,
  unGroupLeaseDoc,
} from "../../../redux/reducers/documentsDataSlice";
import { selectLeaseStatus } from "../../../redux/reducers/leaseStatusSlice";
import {
  selectFields,
  selectInboxId,
  updateColumnOrder,
  updateFieldOrder,
} from "../../../redux/reducers/metadataSlice";
import { setAbstraction, setFieldToBeSelectedInEdit, setMenu } from "../../../redux/reducers/popupSlice";
import {
  selectCurrentHeight,
  selectFontPer,
  selectResolutionMetadata,
  selectTableFontSize,
  selectCustomActiveRowHeight,
  selectCustomInActiveRowHeight,
  selectCustomCellPadding,
  selectCustomLineHeight,
} from "../../../redux/reducers/uiSlice";
import "./Table.scss";
import TableCell from "./table-cell/TableCell";
import TableHeader from "./table-header/TableHeader";
import { updateDocumentStatusForReadStatus } from "../../../redux/reducers/documentsStatusSlice";
import { getUpdatedCustomFontSize } from "../../../common/util";
import {
  selectedRelatedUnmatchedDocs,
  selectParentsWithChildren,
  setParentsWithChildren,
  setRelatedUnmatchedDocIds,
  setRelatedUnmatchedDocuments,
} from "../../../redux/reducers/documentsSlice";
import { selectUserTableFontSize } from "../../../redux/reducers/settingsSlice";
import classNames from "classnames";
import { selectSelectedTabFilterInfo } from "../../../redux/reducers/tabFilterSlice";
import { selectReadOnlyApp } from "../../../redux/reducers/appSlice";

// Images import

function Table(props: CustomDashboardParams) {
  const gridRef = useRef<AgGridReact<DocumentEntity>>(null);
  const uiTableFontSize = useAppSelector(selectTableFontSize);
  const selectedTabFilter = useAppSelector(selectSelectedTabFilterInfo);

  const isReadOnly = useAppSelector(selectReadOnlyApp);
  //Font change Per
  const fontChangePer = useAppSelector(selectFontPer);
  const resMetadata = useAppSelector(selectResolutionMetadata);
  const userActiveRowHeight = Number(
    useAppSelector(selectCustomActiveRowHeight)
  );
  const userInActiveRowHeight = Number(
    useAppSelector(selectCustomInActiveRowHeight)
  );
  const userCellPadding = Number(useAppSelector(selectCustomCellPadding));
  const userLineHeight = Number(useAppSelector(selectCustomLineHeight));
  const userTableFontSize = Number(
    useAppSelector(selectUserTableFontSize).value
  );
  const currentScreenHeight = useAppSelector(selectCurrentHeight);

  const userActiveLine = useAppSelector(selectCustomActiveRowHeight);
  const userInActiveLine = useAppSelector(selectCustomInActiveRowHeight);

  // Selected Tab
  const selectedTab = useAppSelector(selectCurrentTab);
  const inboxTabId = useAppSelector(selectInboxId);
  const isDocumentDeleted = useAppSelector(selectIsDocumentDeleted);
  const unMatchedRelatedDocIds = useAppSelector(selectedRelatedUnmatchedDocs);
  // Headers Selection
  const headers = useAppSelector(selectFields);
  // Rows App Selector
  const rows = useAppSelector(selectRows);
  const selectedDocumentId = useAppSelector(selectSelectedDocumentId);
  // Children For Current Selected Row
  const expandedParent = useAppSelector(selectSelectedDocumentExpandedId);
  const prevExpandedParent = useAppSelector(selectPrevDocumentExpandedId);
  const parentsWithChildren = useAppSelector(selectParentsWithChildren);

  // Children master details
  const leaseStatusFilters = useAppSelector(selectLeaseStatus);
  const dispatch = useAppDispatch();

  // Shadow ref
  const beforeShadowRef = useRef("");
  const afterShadowRef = useRef("");

  const customStyle = {
    height:
      currentScreenHeight < 900 ? "calc(100% - 32px)" : "calc(100% - 52px)",
    "--custom-font-size": uiTableFontSize + "px",
    "--custom-line-height": userLineHeight,
    "--custom-cell-padding": userCellPadding + "px",
    "--custom-dashboard-author-font-size": getUpdatedCustomFontSize(
      "--custom-dashboard-author-font-size"
    ),
  };

  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null);
  const [expandTo10Rows, setExpandTo10Rows] = useState<Boolean | null>(false);
  const [lastExpandStatus, setLastExpandStatus] = useState<Boolean | null>(
    null
  );

  const findExpandedDocument = () => {
    let expandedId = "";

    for (const key in rows) {
      if (rows.hasOwnProperty(key)) {
        const doc = rows[key].find((item) => item.expanded);
        if (doc) {
          expandedId = doc.id;
          dispatch(setDocumentExpanded(expandedId));
        }
      }
    }
  };

  const getRowHeight = useCallback(
    (params: RowHeightParams) => {
      if (params.data.locked) {
        return (
          userActiveLine * userTableFontSize * userLineHeight +
          userCellPadding * 2
        );
      }
      return (
        userInActiveLine * userTableFontSize * userLineHeight +
        userCellPadding * 2
      );
    },
    [fontChangePer]
  );
  //  Expanding row height for one always
  useEffect(() => {
    if (
      (!_.isEmpty(rows) && isDocumentDeleted) ||
      selectedDocumentId === undefined
    ) {
      gridRef.current?.api?.forEachNode(function (rowNode) {
        if (rowNode.data?.id === transformedRowsData[0].id) {
          rowNode.setRowHeight(
            (expandTo10Rows && rowNode.data?.id === selectedDocumentId
              ? userActiveLine
              : userInActiveLine) *
              userTableFontSize *
              userLineHeight +
              userCellPadding * 2
          );
          rowNode.setSelected(true);
          gridRef.current?.api.onRowHeightChanged();
          dispatch(setSelectedDocument(rowNode.data));
          dispatch(setDocumentDeleted(false));
        }
        if (
          !rowNode.data?.locked &&
          !rowNode.isSelected() &&
          userInActiveRowHeight > 0
        ) {
          rowNode.setRowHeight(
            (expandTo10Rows && rowNode.data?.id === selectedDocumentId
              ? userActiveLine
              : userInActiveLine) *
              uiTableFontSize *
              userLineHeight +
              userCellPadding * 2
          );
        }
      });
    }
  }, [isDocumentDeleted, rows, expandTo10Rows]);

  // Resizing Row Height
  useEffect(() => {
    gridRef?.current?.api?.forEachNode(function (rowNode) {
      if (
        !rowNode.data?.locked &&
        !rowNode.isSelected() &&
        userInActiveRowHeight > 0
      ) {
        rowNode.setRowHeight(
          (expandTo10Rows && rowNode.data?.id === selectedDocumentId
            ? userActiveLine
            : userInActiveLine) *
            uiTableFontSize *
            userLineHeight +
            userCellPadding * 2
        );
      } else if (
        rowNode.data?.locked ||
        (rowNode.isSelected() && userActiveRowHeight > 0)
      ) {
        rowNode.setRowHeight(
          (expandTo10Rows && rowNode.data?.id === selectedDocumentId
            ? userActiveLine
            : userInActiveLine) *
            uiTableFontSize *
            userLineHeight +
            userCellPadding * 2
        );
      }
    });
    const headerLines = uiTableFontSize > 18 ? 2.75 : 2;
    gridRef?.current?.api?.setHeaderHeight(
      headerLines * uiTableFontSize * userLineHeight + userCellPadding * 2
    );
    gridRef?.current?.api?.onRowHeightChanged();
    regulateParentChildShadow();
  }, [
    userInActiveRowHeight,
    userActiveRowHeight,
    userCellPadding,
    uiTableFontSize,
    rows,
    expandedParent,
    parentsWithChildren,
  ]);

  const getColumnWidth = (headerKey: String) => {
    switch (headerKey) {
      case CUSTOM_COLUMN_KEY.PIN:
        return resMetadata.columns_dimension.PIN.widthPX;
      case CUSTOM_COLUMN_KEY.AUTHOR:
        return resMetadata.columns_dimension.AUTHOR.widthPX;
      case CUSTOM_COLUMN_KEY.STATUS:
        return resMetadata.columns_dimension.STATUS.widthPX;
      case CUSTOM_COLUMN_KEY.MARKET:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.LOI_DATE_KEY:
        return resMetadata.columns_dimension.LOI_DATE.widthPX;
      case CUSTOM_COLUMN_KEY.STATE:
        return resMetadata.columns_dimension.STATE.widthPX;
      case CUSTOM_COLUMN_KEY.CITY:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.TRADE_AREA:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.TENANT_ENTITY:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.TENANT:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.LANDLORD_ENTITY:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.PROJECT:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.ADDRESS:
        return resMetadata.columns_dimension.MARKET.widthPX + 20;
      case CUSTOM_COLUMN_KEY.SUITE:
        return resMetadata.columns_dimension.SUITE.widthPX;
      case CUSTOM_COLUMN_KEY.SIZE:
        return resMetadata.columns_dimension.SUITE.widthPX;
      case CUSTOM_COLUMN_KEY.BASE_RENT:
        return resMetadata.columns_dimension.SUITE.widthPX;
      case CUSTOM_COLUMN_KEY.TIA:
        return resMetadata.columns_dimension.SUITE.widthPX;
      case CUSTOM_COLUMN_KEY.OPTIONS:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.TERM:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.NNNN_ESTIMATE:
        return resMetadata.columns_dimension.STATE.widthPX;
      case CUSTOM_COLUMN_KEY.RET:
        return resMetadata.columns_dimension.SUITE.widthPX;
      case CUSTOM_COLUMN_KEY.CAM:
        return resMetadata.columns_dimension.SUITE.widthPX;
      case CUSTOM_COLUMN_KEY.UNCONTROLLED_CAM:
        return resMetadata.columns_dimension.SUITE.widthPX;
      case CUSTOM_COLUMN_KEY.CONTROLLED_CAM:
        return resMetadata.columns_dimension.SUITE.widthPX;
      case CUSTOM_COLUMN_KEY.INS:
        return resMetadata.columns_dimension.SUITE.widthPX;
      case CUSTOM_COLUMN_KEY.DELIVERY_DATE:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.RENT_ABATEMENT:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.PERCENTAGE_RENT:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.NOTES:
        return resMetadata.columns_dimension.NOTES.widthPX;
      case CUSTOM_COLUMN_KEY.NOTES_HIDDEN:
        return resMetadata.columns_dimension.NOTES_HIDDEN.widthPX;
      case CUSTOM_COLUMN_KEY.STATUS_HIDDEN:
        return resMetadata.columns_dimension.STATUS_HIDDEN.widthPX;
      case CUSTOM_COLUMN_KEY.CHILDREN:
        return resMetadata.columns_dimension.CHILDREN.widthPX;
      case CUSTOM_COLUMN_KEY.DOCUMENT_NAME:
        return resMetadata.columns_dimension.MARKET.widthPX;
      default:
        return resMetadata.columns_dimension.OTHERS.widthPX;
    }
  };

  const transformedHeadersDef = useMemo(() => {
    if (headers.length > 0) return transformHeaders(headers);
  }, [headers, selectedDocumentId]);

  const transformedRowsData = useMemo(() => {
    // const clientsWithData = Object.keys(rows);
    // const dummyRowsFromIndex = localStorage.getItem(CLIENT_DUMMY_ROW_IDS);
    let data: DocumentEntity[] = [];
    if (selectedTab != undefined && !_.isEmpty(rows)) {
      if (selectedTab in rows) {
        if (inboxTabId !== selectedTab) {
          let relatedDocuments: any = [];
          const anchorTabIDs: any = _.map(rows[selectedTab], "id");
          if (rows[selectedTab]?.length > 0)
            rows[selectedTab]?.forEach((anchorDoc) => {
              const relatedfilteredDocs = rows[inboxTabId]?.filter(
                (inboxDoc) => {
                  // Getting Childrens
                  if (
                    anchorDoc.id === inboxDoc.parent &&
                    anchorTabIDs?.indexOf(inboxDoc.id) === -1
                  ) {
                    return inboxDoc;
                  }
                  // Get Parent and Siblings
                  if (
                    anchorDoc.parent !== "" &&
                    (inboxDoc.parent === anchorDoc.parent ||
                      inboxDoc.id === anchorDoc.parent) &&
                    anchorTabIDs?.indexOf(inboxDoc.id) === -1
                  ) {
                    return inboxDoc;
                  }
                }
              );
              relatedDocuments = [...relatedDocuments, ...relatedfilteredDocs];
            });
          if (rows[selectedTab]?.length > 0) {
            data = rows[selectedTab];
            if (relatedDocuments?.length > 0) {
              const uniqRelatedDocs: DocumentEntity[] =
                _.uniq(relatedDocuments);
              dispatch(setRelatedUnmatchedDocIds(_.map(uniqRelatedDocs, "id")));
              dispatch(setRelatedUnmatchedDocuments(uniqRelatedDocs));
              data = [...data, ...uniqRelatedDocs];
            }
          } else {
            data = [];
          }
        } else {
          dispatch(setRelatedUnmatchedDocIds([]));
          dispatch(setRelatedUnmatchedDocuments([]));
          data = rows[selectedTab];
        }
      }
      // data = rows[selectedTab].filter((row) => {
      //   return row.parent == '' || expandedParent.includes(row.parent);
      // });
    }

    // Sort the array in descending order based on the 'ts' property
    /*const sortedData =
      data &&
      [...data].sort(
        (a, b) => Number(b.dashboardOrder) - Number(a.dashboardOrder)
      );*/

    const sortedData =
      data &&
      [...data].sort((a, b) => {
        // First, compare by `ts` (timestamp) in descending order
        const tsA = new Date(a.ts).getTime();
        const tsB = new Date(b.ts).getTime();

        return (
          (() => {
            const valueA =
              a.values.find((v) => v.field === "008b")?.value || "";
            const valueB =
              b.values.find((v) => v.field === "008b")?.value || "";

            // Handle the case for "---" by considering it as a low priority value (descending order)
            if (valueA === "---" && valueB !== "---") return 1; // "---" comes last
            if (valueB === "---" && valueA !== "---") return -1; // "---" comes last

            // If both are dates, convert to Date objects
            const dateA = new Date(valueA);
            const dateB = new Date(valueB);

            // If either is an invalid date, consider the invalid date as "later" (descending order)
            if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) return 0;
            if (isNaN(dateA.getTime())) return 1; // invalid date in a should come later
            if (isNaN(dateB.getTime())) return -1; // invalid date in b should come later

            // Return the comparison of the dates in descending order
            return dateB.getTime() - dateA.getTime();
          })() ||
          // Second, compare by `ts` (timestamp) if the 008b field is identical
          tsB - tsA ||
          Number(b.dashboardOrder) - Number(a.dashboardOrder)
        );
      });

    // Create a mapping to store children for each parent
    const childrenMap: Record<string, any[]> = {};
    sortedData &&
      sortedData.forEach((item) => {
        if (item.parent) {
          if (!childrenMap[item.parent]) {
            childrenMap[item.parent] = [];
          }
          childrenMap[item.parent].push(item);
        }
      });

    // Rearrange the array by grouping parents and their children
    const arrangedData: any[] = [];
    sortedData &&
      sortedData.forEach((item) => {
        if (!item.parent) arrangedData.push(item);
        if (childrenMap[item.id] && expandedParent === item.id) {
          // Add children sorted by ts in descending order
          arrangedData.push(
            ...childrenMap[item.id].sort((a, b) => b.grpOrder - a.grpOrder)
          );
        }
      });
    return arrangedData;
  }, [rows, selectedTab, expandedParent, parentsWithChildren]);

  // useEffect(() => {
  //   if (rows[selectedTab]) {
  //     gridRef.current!.api.setRowData(rows[selectedTab]);
  //     gridRef.current!.api.redrawRows();
  //   }
  // }, [selectedTab]);

  useEffect(() => {
    if (selectedTab != undefined && !_.isEmpty(rows))
      if (selectedTab in rows) {
        dispatch(setParentsWithChildren([]));
        var parentsWithChildren: string[] = [];
        rows[selectedTab] &&
          rows[selectedTab].forEach((doc) => {
            if (doc.parent !== "") {
              if (!parentsWithChildren.includes(doc.parent)) {
                parentsWithChildren.push(doc.parent);
              }
            }
          });
        dispatch(setParentsWithChildren([...parentsWithChildren]));
      }
  }, [selectedTab, rows]);

  const components = useMemo(
    () => ({
      agColumnHeader: TableHeader,
    }),
    []
  );

  function transformHeaders(headers: FieldEntity[]): ColDef[] {
    // Prepare the Column Defination
    function prepareColDef(header: FieldEntity) {
      // if (header.code == CUSTOM_COLUMN_KEY.AUTHOR && header.code == CUSTOM_COLUMN_KEY.TRADE_AREA && header.code == CUSTOM_COLUMN_KEY.MARKET) {
      //   return
      // }
      // Default Column Def
      let colDef: ColDef = {
        colId:
          header.code === CUSTOM_COLUMN_KEY.NOTES_HIDDEN ||
          CUSTOM_COLUMN_KEY.STATUS_HIDDEN
            ? header.code
            : header.name,
        headerName: header.name,
        pinned: isColumnPinnedAndLocked(header.pinned, header.order),
        headerComponentParams: {
          customData: header,
        },
        headerClass: (params) => {
          const classNames = [];
          // if (header.order < 0 || header.order == 100 || header.order == 101) {
          if (header.order == 102) {
            classNames.push("pinned-column-header");
          } else if (
            isColumnPinnedAndLocked(header.pinned, header.order) != null
          ) {
            classNames.push("pinned-column-header");
          } else {
            classNames.push("normal-column-color");
          }
          return classNames;
        },
        wrapHeaderText: true,
        wrapText: true,
        cellClass: (params) => {
          const classNames: string[] = [];
          if (params.data.id == selectedDocumentId) {
            classNames.push("selected-row-color");
          } else {
            if (
              header.order < 0 ||
              header.order == 100 ||
              header.order == 101
            ) {
              classNames.push("pinned-column-cell-color");
            } else if (header.order == 0) {
              classNames.push("market-column");
            }
          }
          if (header.code == CUSTOM_COLUMN_KEY.NOTES_HIDDEN) {
            classNames.push(CUSTOM_COLUMN_KEY.NOTES_HIDDEN);
          }
          if (header.code === CUSTOM_COLUMN_KEY.CHILDREN) {
            classNames.push("expand-collapse-parent-div");
          }
          return classNames;
        },
        minWidth: 20,
        suppressSizeToFit: true,
        suppressMovable:
          header.order < 0 || header.order >= 100 || isReadOnly ? true : false,
        resizable: header.order !== 102 || !isReadOnly ? true : false,
        width:
          getColumnWidth(header.code) +
          getColumnWidth(header.code) * header.widthChange,
        lockPosition: header.pinned
          ? header.order < 0
            ? "left"
            : header.order > 100
            ? "right"
            : undefined
          : undefined,
        initialHide:
          header.code == CUSTOM_COLUMN_KEY.NOTES_HIDDEN ||
          header.code == CUSTOM_COLUMN_KEY.STATUS_HIDDEN
            ? true
            : false,
        cellRenderer: TableCell,
        cellRendererParams: { customData: header },
      };

      if (header.order < 0) {
        colDef["lockPosition"] = "left";
      } else if (header.order >= 100) {
        colDef["lockPosition"] = "right";
      }

      if (header.code === "notes") {
        colDef.suppressKeyboardEvent = suppressArrowNavigation;
      }
      // colDef["cellRenderer"] = TableCell;
      // colDef["cellRendererParams"] = { customData: header };

      // if (header.code === 'trade') {
      //   colDef['editable'] = true;
      // }

      return colDef;
    }

    function suppressArrowNavigation(
      params: SuppressKeyboardEventParams
    ): boolean {
      const key = params.event.key;

      return (
        key === "ArrowUp" ||
        key === "ArrowDown" ||
        key === "ArrowLeft" ||
        key === "ArrowRight"
      );
    }

    function isColumnPinnedAndLocked(customPinned: boolean, order: number) {
      if (order < 0 || customPinned) {
        return "left";
      } else if (order >= 100) {
        return "right";
      }
      return null;
    }

    const tempHeaders = headers.filter(
      (e) =>
        ![
          CUSTOM_COLUMN_KEY.AUTHOR,
          CUSTOM_COLUMN_KEY.MARKET,
          CUSTOM_COLUMN_KEY.TRADE_AREA,
        ].includes(e.code)
    );

    return [...tempHeaders]
      .sort((h1, h2) => h1.order - h2.order)
      .map((header) => prepareColDef(header));
  }

  // function prepareRows(rows: DType[], filterBy: string) {
  function prepareRows(rows: DocumentEntity[]) {
    let fieldValues: DocumentEntity[] = rows;
    return fieldValues;
  }

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams<DocumentEntity>) => params.data.id;
  }, []);

  const isRowSelectable = (rowData: IRowNode<DocumentEntity>) => {
    return rowData.data?.status != LEASE_STATUS.DUMMY;
  };

  const handleCellClick = (event: CellClickedEvent) => {
    const restrictiveFieldCodes = ["Children"];
    // [
    //   "Author",
    //   "LOI Status",
    //   "Market",
    //   "Children",
    //   "",
    // ];

    // if (event.column?.getColId() === 'LOI Status') {
    //   return;
    // }
    const headersName = headers
      .filter((header) => restrictiveFieldCodes.indexOf(header.name) >= 0)
      .map((header) => header.name);

    if (event.colDef.colId && headersName.indexOf(event.colDef.colId) >= 0) {
      if (event.colDef.colId === "Children") {
        return;
      } else if (event.colDef.colId === "LOI Status") {
        // displayMenu(event, event.node.data, MENU_SUB_TYPE.AUTHOR);
      }
    } else {
      gridRef.current?.api.forEachNode(function (rowNode) {
        if (rowNode.data?.id && rowNode.isSelected()) {
          if (!rowNode.data.read) {
            updateDocumentReadStatus(rowNode.data?.id, true, false)?.then(
              () => {
                dispatch(
                  markDocumentRead({
                    clientId: selectedTab,
                    documentId: rowNode.data?.id,
                    status: true,
                  })
                );
                dispatch(
                  updateDocumentStatusForReadStatus({
                    documentId: rowNode.data?.id,
                    isRead: true,
                  })
                );
                dispatch(updateRowReadStatus(true)).then(() =>
                  updateDocumentReadStatus(rowNode.data?.id, true, false)
                );
              }
            );
          }
          if (event.colDef.colId === "children") {
            rowNode.setRowHeight(
              userInActiveLine * userTableFontSize * userLineHeight +
                userCellPadding * 2
            );
          } else {
            dispatch(setDocumentRowAsExpanded(event.data));
            rowNode.setRowHeight(
              userActiveLine * userTableFontSize * userLineHeight +
                userCellPadding * 2
            );
          }
        } else {
          if (!rowNode.data?.locked)
            rowNode.setRowHeight(
              userInActiveLine * userTableFontSize * userLineHeight +
                userCellPadding * 2
            );
        }
      });
      event.api.onRowHeightChanged();
    }
  };

  // handle the row selection
  // const handleRowSelection = (event: RowSelectedEvent) => {
  //   gridRef.current?.api.forEachNode(function (rowNode) {
  //     if (rowNode.data?.id && rowNode.isSelected()) {
  //       rowNode.setRowHeight(
  //         resMetadata.headerLineHeight * 10 + userCellPadding * 2
  //       );
  //     } else {
  //       if (!rowNode.data?.locked) rowNode.setRowHeight(uiInactiveRowHeight);
  //     }
  //   });
  //   event.api.onRowHeightChanged();
  //   dispatch(setSelectedDocument(event.data));
  // };

  // Show abstraction on the double click
  const handleCellDoubleClick = useCallback(
    (event: CellDoubleClickedEvent) => {
      const isUnMatchedDocument =
        rows?.[selectedTab]?.findIndex((e) => e.id === event.data.id) === -1;

      if (event.colDef.colId === "children") {
        return;
      }
      if (isUnMatchedDocument) {
        return;
      }
      const fieldCode = event.colDef.colId;
      const fieldValue = event.data?.values.filter((e: any) => e.field === fieldCode)[0]?.value; 
      dispatch(setFieldToBeSelectedInEdit({ fieldCode: fieldCode, value: fieldValue }));
      dispatch(setMenu(NONE_MENU_ID));
      dispatch(setAbstraction());
      dispatch(fetchDocumentHTML());
    },
    [rows, selectedTab]
  );

  const handleClick = (event: CellClickedEvent | CellDoubleClickedEvent) => {
    dispatch(setSelectedDocument(event.data));
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
      handleCellDoubleClick(event);
      if (lastExpandStatus !== null) setExpandTo10Rows(lastExpandStatus);
      setLastExpandStatus(null);
    } else {
      if (
        event.colDef?.colId === CUSTOM_COLUMN_KEY.CHILDREN ||
        event.colDef?.colId === CUSTOM_COLUMN_KEY.PIN ||
        event.colDef?.colId === CUSTOM_COLUMN_KEY.NOTES ||
        event.colDef?.colId === CUSTOM_COLUMN_KEY.STATUS
      ) {
        if (selectedDocumentId !== event.data.id) {
          gridRef.current?.api?.forEachNode(function (rowNode) {
            if (
              !rowNode.data?.locked &&
              !rowNode.isSelected() &&
              userInActiveRowHeight > 0
            ) {
              rowNode.setRowHeight(
                  userInActiveLine *
                  uiTableFontSize *
                  userLineHeight +
                  userCellPadding * 2
              );
            }
          });
          setExpandTo10Rows(false);
          handleScrollSizeChange();
        }
        return;
      }
      if (selectedDocumentId === event.data.id) {
        setLastExpandStatus(expandTo10Rows);
        setExpandTo10Rows((prev) => !prev);
      } else {
        setExpandTo10Rows(false);
      }
      const timeout = setTimeout(() => {
        handleCellClick(event);
        setClickTimeout(null);
      }, 210) as NodeJS.Timeout;
      setClickTimeout(timeout);
    }
  };

  const isExternalFilterPresent = useCallback(() => {
    return true;
  }, []);

  const doesExternalFilterPass = useCallback(
    (node: IRowNode<DocumentEntity>) => {
      const appliedFilters = leaseStatusFilters
        .filter((filter) => filter.selected)
        .map((filter) => filter.name);
      if (node.data) {
        const lStatus = node.data.status;
        if (appliedFilters.includes(lStatus)) {
          return true;
        }
      }
      return false;
    },
    [leaseStatusFilters]
  );

  useEffect(() => {
    gridRef.current?.api?.onFilterChanged();
  }, [leaseStatusFilters]);

  const scrollWidth = document.getElementsByClassName(
    "ag-body-vertical-scroll"
  )[0]?.clientWidth;
  // Aligining Deal Notes to factor customized scroll design
  useEffect(() => {
    window.addEventListener("resize", handleScrollSizeChange);
    return () => {
      window.removeEventListener("resize", handleScrollSizeChange);
    };
  }, [rows, scrollWidth]);

  const handleScrollSizeChange = () => {
    setTimeout(() => {
      const width =
        document.getElementsByClassName("ag-body-vertical-scroll")[0]
          ?.clientWidth || 0;
      var pinnedRightHeader = document.querySelector(".ag-pinned-right-header");
      if (width > 0) {
        pinnedRightHeader?.classList.remove(
          "ag-pinned-right-header-without-scroll"
        );
        pinnedRightHeader?.classList.add("ag-pinned-right-header-with-scroll");
      } else {
        pinnedRightHeader?.classList.add(
          "ag-pinned-right-header-without-scroll"
        );
        pinnedRightHeader?.classList.remove(
          "ag-pinned-right-header-with-scroll"
        );
      }
    }, 500);
  };

  const getRowClass = (params: any) => {
    if (params.data.parent !== "") {
      return "row-child-lease";
    }
    return "";
  };

  let resizeTimer: ReturnType<typeof setTimeout>;
  const handleColResize = (event: ColumnResizedEvent) => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      onColumnResized(event);
    }, 300);
  };

  function onColumnResized(event: ColumnResizedEvent) {
    let fieldEntity: FieldEntity =
      event.column?.getColDef().cellRendererParams.customData;
    let widthChange =
      ((event.column?.getActualWidth() || 0) -
        getColumnWidth(fieldEntity?.code)) /
      getColumnWidth(fieldEntity?.code);
    dispatch(updateFieldResize({ ...fieldEntity, widthChange }));
  }

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.forceUpdate();
    }
  }, [transformedRowsData]);

  // Drag Functions
  const dropOverIdCSSRef = useRef("");
  const dropOverOrderRef = useRef<Number>(-1);

  function onDragMove(event: RowDragMoveEvent) {
    const draggedDoc = event.node.data;
    let dropOverDoc = event.overNode?.data;
    const prevDraggedId = dropOverIdCSSRef.current;
    if (prevDraggedId) {
      removeSortDropHighlight(prevDraggedId);   
    }
    dropOverOrderRef.current = -1;
    if (
      dropOverDoc?.parent === "" &&
      draggedDoc?.parent !== "" &&
      // || (draggedDoc?.parent === '' && dropOverDoc?.parent !== ''))
      draggedDoc?.id !== dropOverDoc?.parent &&
      draggedDoc?.parent !== dropOverDoc?.id
    ) {
      return;
    }
    if (event.overIndex >= 0) {
      const dropCSSId = event.overNode?.data?.id;
      dropOverIdCSSRef.current = dropCSSId;
      let loiToBeModified = event.overNode?.data?.id;
      let classToBeAdded = "";

      const isDraggedNotOutsideGroup = draggedDoc.id !== dropOverDoc.parent || dropOverDoc.id !== draggedDoc.parent;
      const isSortingWithInGroup = ((dropOverDoc.parent !== '' && draggedDoc.parent !=='') && 
        dropOverDoc.parent === draggedDoc.parent
        || dropOverDoc.parent === draggedDoc.id
        || dropOverDoc.id === draggedDoc.parent);
      const isDropNotOnSameDoc = draggedDoc.id !== dropOverDoc.id;
      // Drag Sorting
      if (isDropNotOnSameDoc && isSortingWithInGroup && isDraggedNotOutsideGroup) {
        if (
          // Dragging a Child LOI, Dragged LOI order < Dropover LOI order
          (draggedDoc.parent !== "" &&
            dropOverDoc.grpOrder >= draggedDoc.grpOrder) ||
          // Dragging a Child LOI , parent change
          draggedDoc.parent === dropOverDoc.id
        ) {
          classToBeAdded = "row-sort-over-up";
        } else {
          classToBeAdded = "row-sort-over-down";
        }

        // When user sort drags a doc over an expanded group
        // if (
        //   parentsWithChildren.includes(groupParentDoc?.id) &&
        //   expandedParent === groupParentDoc?.id && // checks whether its a parent and expanded
        //   draggedDoc.parent !== dropOverDoc.id && // Whether child changes parent posiiton
        //   ((draggedDoc.parent === "" && dropOverDoc.parent === "") || // Group LOIs need not come
        //     (draggedDoc.parent !== dropOverDoc.parent &&
        //       draggedDoc.id !== dropOverDoc.parent))
        // ) {
        //   if (groupParentDoc?.dashboardOrder < draggedDoc?.dashboardOrder) {
        //     loiToBeModified = lastChildOfGroup?.id;
        //   } else {
        //     loiToBeModified = lastChildOfGroup?.parent;
        //   }
        //   dropOverOrderRef.current = groupParentDoc?.dashboardOrder;
        // }
      } else {
        // Drag Grouping
        if (
          (draggedDoc?.parent !== dropOverDoc?.parent ||
            (draggedDoc?.parent === "" && dropOverDoc?.parent === "")) &&
          draggedDoc?.parent !== dropOverDoc?.id &&
          (!parentsWithChildren.includes(draggedDoc?.id) ||
            !parentsWithChildren.includes(dropOverDoc?.id)) &&
          !parentsWithChildren.includes(draggedDoc?.id)
        )
          classToBeAdded = "row-drop-over";
        else return;
      }
      const dropOverDocEl = document.querySelectorAll(
        `[row-id="${loiToBeModified}"]`
      );
      dropOverDocEl &&
        dropOverDocEl.forEach((el) => el.classList.add(classToBeAdded));
      dropOverIdCSSRef.current = loiToBeModified;
    }
  }

  function handleDragGrouping(
    event: RowDragEndEvent,
    draggedDoc: DocumentEntity,
    dropOverDoc: DocumentEntity
  ) {
    const dropOverDocId =
      event.overNode?.data?.parent === ""
        ? event.overNode?.data?.id
        : event.overNode?.data?.parent;
    // Drag an orphan to make grouping
    if (
      draggedDoc.parent !== "" &&
      draggedDoc.parent === event.overNode?.data?.parent
    ) {
      dispatch(
        dragLeaseMapping({
          parentId: draggedDoc.parent,
          childId: draggedDoc.id,
          newChildDocOrder: dropOverDoc.order,
        })
      ).then((res) => {
        if (res) {
          return;
        }
      });
    } else if (
      draggedDoc.parent === "" &&
      dropOverDocId !== draggedDoc.id &&
      dropOverDoc?.id &&
      !parentsWithChildren.includes(draggedDoc.id) // To check parent ungrouping
    ) {
      let groupPayload = {
        parentId: dropOverDocId,
        childId: draggedDoc.id,
        newChildDocOrder: dropOverDoc.grpOrder,
      };
      if (parentsWithChildren.includes(draggedDoc?.id)) {
        groupPayload.parentId = draggedDoc.id;
        groupPayload.childId = dropOverDocId;
        groupPayload.newChildDocOrder = 0;
      }
      dispatch(dragLeaseMapping(groupPayload)).then((res) => {
        if (res) {
          // dispatch(setDocumentExpanded(groupPayload?.parentId));
          if (expandedParent !== groupPayload?.parentId) {
            handleRowExpandCollapse(groupPayload?.parentId);
          }
        }
      });
    }
  }

  function handleDragUnGrouping(
    event: RowDragEndEvent,
    draggedDoc: DocumentEntity,
    dropOverDoc: DocumentEntity,
  ) {
    let ungroupPayload = {
      parentId: "",
      childId: draggedDoc?.id,
      newChildDocOrder:
        dropOverDoc?.dashboardOrder || draggedDoc?.dashboardOrder || 0,
      isParentChange: draggedDoc?.parent === '',
    };
    dispatch(dragLeaseMapping(ungroupPayload)).then((res) => {
      if (res?.payload) {
        removeChildHighlight(draggedDoc.id);
        removeSortDropHighlight(draggedDoc.id);
        dispatch(unGroupLeaseDoc({ inboxTabId, draggedDoc }));
      }
    });
  }

  function handleDragSorting(
    event: RowDragEndEvent,
    draggedDoc: DocumentEntity,
    dropOverDoc: DocumentEntity
  ) {
    let overIndex;
    if (
      dropOverDoc?.parent === "" &&
      draggedDoc?.parent !== "" &&
      // || (draggedDoc?.parent === '' && dropOverDoc?.parent !== ''))
      draggedDoc?.id !== dropOverDoc?.parent &&
      draggedDoc?.parent !== dropOverDoc?.id
    ) {
      return;
    }
    if (draggedDoc.parent === "") {
      overIndex = dropOverDoc?.dashboardOrder;
    } else {
      overIndex = dropOverDoc?.grpOrder;
    }

    // Dragging Parent to Child
    if (draggedDoc?.id === dropOverDoc?.parent) {
      const nextParent = rows[selectedTab]
        ?.filter((e) => e.parent === draggedDoc.id)
        .sort((a, b) => Number(b.grpOrder) - Number(a.grpOrder))[0];
      dispatch(
        dragLeaseMapping({
          parentId: dropOverDoc?.parent,
          childId: dropOverDoc?.id,
          newChildDocOrder: dropOverDoc?.grpOrder,
          isParentChange: true,
        })
      ).then(() => {
        removeGreyGroupShadow(expandedParent);
        setTimeout(() => {
          // dispatch(setDocumentExpanded(nextParent?.id));
          handleRowExpandCollapse(nextParent?.id);
        }, 100);
      });
      return;
    }

    // Dragging Child to change Parent
    if (draggedDoc?.parent === dropOverDoc?.id) {
      dispatch(
        dragLeaseMapping({
          parentId: draggedDoc.parent,
          childId: draggedDoc.id,
          newChildDocOrder: 0,
          isParentChange: true,
        })
      ).then(() => {
        removeGreyGroupShadow(expandedParent);
        setTimeout(() => {
          // dispatch(setDocumentExpanded(draggedDoc.id));
          handleRowExpandCollapse(draggedDoc?.id);
        }, 10);
      });
      return;
    }
    dispatch(
      dragLeaseMapping({
        parentId: draggedDoc.parent,
        childId: draggedDoc.id,
        newChildDocOrder:
          Number(dropOverOrderRef.current) > -1
            ? dropOverOrderRef.current
            : overIndex,
        isParentChange: false,
      })
    );
    dropOverOrderRef.current = -1;
  }

  function onDragEnd(event: RowDragEndEvent) {
    const draggedDoc = event.node.data;
    const dropOverDoc = event.overNode?.data;
    const isSortingWithInGroup =((dropOverDoc?.parent !== '' && draggedDoc.parent !=='') && 
      dropOverDoc?.parent === draggedDoc.parent
      || dropOverDoc?.parent === draggedDoc.id
      || dropOverDoc?.id === draggedDoc.parent);
    const isDropOnSameDoc = draggedDoc.id === dropOverDoc?.id;
    if (!isSortingWithInGroup || isDropOnSameDoc) {
      const isDocInsideGroup = parentsWithChildren.includes(draggedDoc.id) || parentsWithChildren.includes(draggedDoc?.parent);
      if (isDocInsideGroup) {
        handleDragUnGrouping(event, draggedDoc, dropOverDoc);
      } else if (
        draggedDoc?.parent === "" &&
        draggedDoc?.parent !== dropOverDoc?.id &&
        (!parentsWithChildren.includes(draggedDoc?.id) ||
          !parentsWithChildren.includes(dropOverDoc?.id)) &&
        !parentsWithChildren.includes(draggedDoc?.id)
      ) {
        handleDragGrouping(event, draggedDoc, dropOverDoc);
      } else return;
    } else if (draggedDoc?.id !== dropOverDoc?.id) {
      handleDragSorting(event, draggedDoc, dropOverDoc);
    }
    removeSortDropHighlight(dropOverDoc?.id);
    const prevDraggedId = dropOverIdCSSRef.current;
    if (prevDraggedId) {
      removeSortDropHighlight(prevDraggedId);
    }
  }

  function removeSortDropHighlight(divId: string) {
    const elToBeModified = document.querySelectorAll(`[row-id="${divId}"]`);
    elToBeModified &&
      elToBeModified.forEach((el) => {
        el.classList.remove(
          "row-drop-over",
          "row-sort-over-up",
          "row-sort-over-down"
        );
      });
  }

  function removeChildHighlight(divId: string) {
    const elToBeModified = document.querySelectorAll(`[row-id="${divId}"]`);
    elToBeModified &&
      elToBeModified.forEach((el) => {
        el.classList.remove(
          "row-child-lease",
          "row-drop-over-child",
          "row-drop-over",
          "row-sort-over-up",
          "row-sort-over-down"
        );
      });
    if (parentsWithChildren.includes(divId)) {
      const childIds = transformedRowsData.filter(e => e.parent === divId).map(e => e.id);
      childIds.forEach(e => removeChildHighlight(e));
    }
  }

  function regulateParentChildShadow() {
    removeGreyGroupShadow(prevExpandedParent);
    if (expandedParent !== "" && parentsWithChildren.includes(expandedParent)) {
      const docIndex = transformedRowsData.findIndex(
        (e) => e.id === expandedParent
      );
      const { beforeDocId, afterDocId } = getBeforeAfterDocIds(docIndex);
      beforeShadowRef.current = beforeDocId;
      afterShadowRef.current = afterDocId;
      const visibleChildIds = transformedRowsData
        .filter((e) => e.parent === expandedParent)
        .map((e) => e.id);
      visibleChildIds.forEach((childId) => {
        document.querySelectorAll(`[row-id="${childId}"]`).forEach((el) => {
          el.classList.add("row-child-lease");
        });
      });
      document
        .querySelectorAll(`[row-id="${expandedParent}"]`)
        .forEach((el) => {
          el.classList.add("row-child-lease");
        });
      document.querySelectorAll(`[row-id="${beforeDocId}"]`).forEach((el) => {
        el.classList.add("before-btm-expand-lease");
      });
      document.querySelectorAll(`[row-id="${afterDocId}"]`).forEach((el) => {
        el.classList.add("after-top-expand-lease");
      });
    }
  }

  function removeGreyGroupShadow(parentId: string) {
    // Removing Shadow, grey highlight from prev expanded doc
    const prevDocIndex = transformedRowsData.findIndex(
      (e) => e.id === parentId
    );
    const { beforeDocId, afterDocId } = getBeforeAfterDocIds(prevDocIndex);
    document.querySelectorAll(`[row-id="${parentId}"]`).forEach((el) => {
      el.classList.remove(
        "row-child-lease",
        "before-btm-expand-lease",
        "after-top-expand-lease"
      );
    });
    document.querySelectorAll(`[row-id="${beforeDocId}"]`).forEach((el) => {
      el.classList.remove("before-btm-expand-lease", "after-top-expand-lease");
    });
    document.querySelectorAll(`[row-id="${afterDocId}"]`).forEach((el) => {
      el.classList.remove("before-btm-expand-lease", "after-top-expand-lease");
    });
    document
      .querySelectorAll(`[row-id="${beforeShadowRef.current}"]`)
      ?.forEach((el) => {
        el.classList.remove(
          "before-btm-expand-lease",
          "after-top-expand-lease"
        );
      });
    document
      .querySelectorAll(`[row-id="${afterShadowRef?.current}"]`)
      ?.forEach((el) => {
        el.classList.remove(
          "before-btm-expand-lease",
          "after-top-expand-lease"
        );
      });
  }

  function getBeforeAfterDocIds(docIndex: number) {
    // Find the closest 'parent'='' before the current index
    let beforeDocId = null;
    for (let i = docIndex - 1; i >= 0; i--) {
      if (transformedRowsData[i].parent === "") {
        beforeDocId = transformedRowsData[i].id;
        break;
      }
    }

    // Find the closest 'parent'='' after the current index
    let afterDocId = null;
    for (let i = docIndex + 1; i < transformedRowsData.length; i++) {
      if (transformedRowsData[i].parent === "") {
        afterDocId = transformedRowsData[i].id;
        break;
      }
    }

    return { beforeDocId, afterDocId };
  }

  const handleColumnDrag = useCallback(
    (event: ColumnMovedEvent) => {
      if (event.finished) {
        const draggedCol =
          event.column?.getColDef()?.headerComponentParams?.customData;
        if (draggedCol) {
          const rangeHeaders = [...headers]?.filter(
            (e) => e.order > 0 && e.order < 100
          );
          const movedColPrevOrder = draggedCol.order;
          const movedColNextOrder =
            rangeHeaders[Number(event?.toIndex) - 3].order;
          let toBeUpdatedFields: any[];
          if (movedColNextOrder > movedColPrevOrder) {
            // Dragged column moved down
            toBeUpdatedFields = rangeHeaders
              .filter(
                (e) =>
                  e.order > movedColPrevOrder && e.order <= movedColNextOrder
              )
              .map((col) => {
                const c = { ...col };
                c.order -= 1; // Shift order up
                return c;
              });
          } else if (movedColNextOrder < movedColPrevOrder) {
            // Dragged column moved up
            toBeUpdatedFields = rangeHeaders
              .filter(
                (e) =>
                  e.order >= movedColNextOrder && e.order < movedColPrevOrder
              )
              .map((col) => {
                const c = { ...col };
                c.order += 1; // Shift order down
                return c;
              });
          } else {
            // No movement, no updates needed
            toBeUpdatedFields = [];
          }
          const finalUpdatedFields = [
            ...toBeUpdatedFields,
            { ...draggedCol, order: movedColNextOrder },
          ];
          dispatch(updateFieldOrder(finalUpdatedFields)).then((resp) => {
            dispatch(updateColumnOrder(finalUpdatedFields));
          });
        }
      }
    },
    [headers]
  );

  const handleRowExpandCollapse = (toExpandId: string) => {
    const currentId = toExpandId;
    const isCurrentExpanded = expandedParent === currentId;
    const hasDifferentExpanded = expandedParent && expandedParent !== currentId;

    // Collapse if clicking the already expanded row
    if (isCurrentExpanded) {
      dispatch(setDocumentExpanded(""));
      dispatch(rowCollapseUpdate(currentId));
      return;
    }

    // Collapse previous expanded row if exists
    if (hasDifferentExpanded) {
      dispatch(rowCollapseUpdate(expandedParent));
    }

    // Expand current row
    const expandCurrentRow = () => {
      dispatch(setDocumentExpanded(currentId));
      dispatch(rowExpandUpdate(currentId)).then((res) => {
        if (res) {
          dispatch(
            setDocumentRowAsExpanded({
              clientId: selectedTab,
              id: currentId,
            })
          );
        }
      });
    };

    expandCurrentRow();
  };

  return (
    <>
      <div
        className={classNames("ag-theme-alpine", {
          "pointer-events-none": selectedTabFilter?.name,
        })}
        style={customStyle}
      >
        <AgGridReact
          onColumnMoved={handleColumnDrag}
          ref={gridRef}
          components={components}
          columnDefs={transformedHeadersDef}
          rowData={transformedRowsData}
          getRowHeight={getRowHeight}
          getRowClass={getRowClass}
          headerHeight={
            2 * userTableFontSize * userLineHeight + userCellPadding * 2
          }
          rowSelection="single"
          getRowId={getRowId}
          animateRows={true}
          isRowSelectable={isRowSelectable}
          suppressCellFocus={true}
          onCellClicked={handleClick}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          overlayNoRowsTemplate="No Documents To Show"
          overlayLoadingTemplate="No Documents To Show"
          onRowDataUpdated={() => {
            handleScrollSizeChange();
          }}
          onGridSizeChanged={() => {
            handleScrollSizeChange();
          }}
          onFirstDataRendered={() => {
            handleScrollSizeChange();
            findExpandedDocument();
          }}
          onColumnResized={handleColResize}
          rowDragManaged={false}
          rowDragEntireRow={true}
          suppressRowDrag={true}
          suppressMoveWhenRowDragging={true}
          onRowDragEnd={onDragEnd}
          onRowDragMove={onDragMove}
        ></AgGridReact>
      </div>
    </>
  );
}
export default Table;
