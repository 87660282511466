import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  API_VARIABLE_SELECTED_DOCUMENT_ID,
  API_VARIABLE_USER_ID,
  MARK_ROW_READ,
  MARK_ROW_UNREAD,
  ROW_CHILDREN_HIDE,
  ROW_CHILDREN_SHOW,
  ROW_PIN,
  ROW_UNPIN,
  UPDATE_DOCUMENT_AUTHOR,
  UPDATE_DOCUMENT_MARKET,
  VALUES_HTML,
} from "../../api/api-constants";
import { AXIOS } from "../../api/axios";
import {
  DashboardState,
  SelectedDocumentState,
} from "../../common/types/SliceTypes";
import {
  AuthorStatePayload,
  MarketStatePayload,
} from "../../common/types/dashboard/DashboardFunctionalTypes";
import { FieldValueMetadata } from "../../common/types/dashboard/DashboardUITypes";
import { RootState } from "../store";
import { selectFields } from "./metadataSlice";
import { FieldEntity } from '../../common/types/EntityTypes';
import { mapOfflineFieldEntityToFieldEntity } from '../../common/types/Mapper';

const initial: DashboardState = {
  selectedDocument: {} as SelectedDocumentState,
  selectedTab: "",
  showNotes: true,
  showStatus: true,
  isDragged: false,
  expandedDocumentId: '',
  prevExpandedDocumentId: '',
  isDocumentDeleted: false,
  fieldInEditMode: '',
  textCopiedFromLHS: '',
  dragToSortEnable: false,
  isTabFilterInEditMode: false,
  isUserProfileMenuOpen: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initial,
  reducers: {
    toggleNotes: (state) => {
      state.showNotes = !state.showNotes;
    },
    toggleStatus: (state) => {
      state.showStatus = !state.showStatus;
    },
    toggleIsDragged: (state) => {
      state.isDragged = !state.isDragged;
    },
    setTextCopiedFromLHS: (state, value) => {
      return {
        ...state,
        textCopiedFromLHS: value.payload,
      };
    },
    setUserProfileMenuState: (state, value) => {
      return {
        ...state,
        isUserProfileMenuOpen: value.payload,
      };
    },
    setSelectedTab: (state, value) => {
      return {
        ...state,
        selectedTab: value.payload,
      };
    },
    setFieldInEditMode: (state, value) => {
      return {
        ...state,
        fieldInEditMode: value.payload,
      };
    },
    setDocumentExpanded: (state, value) => {
      const prevDocExpanded = state.expandedDocumentId;
      return {
        ...state,
        expandedDocumentId: value.payload,
        prevExpandedDocumentId: prevDocExpanded,
      };
    },
    setDeSelectDocument: (state) => {
      return {
        ...state,
        selectedDocument: {} as SelectedDocumentState,
      };
    },
    setSelectedDocument: (state, value) => {
      return {
        ...state,
        selectedDocument:
          value.payload?.id === undefined
            ? ({} as SelectedDocumentState)
            : {
                id: value.payload.id,
                name: value.payload.name,
                author: value.payload.author,
                market: value.payload.market,
                notes: value.payload.notes,
                status: value.payload.status,
                values: value.payload.values,
              },
      };
    },
    setDocumentDeleted: (state, value) => {
      return {
        ...state,
        isDocumentDeleted: value.payload,
      };
    },
    setTabFilterEditMode: (state, value) => {
      return {
        ...state,
        isTabFilterInEditMode: value.payload,
      };
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchDocumentHTML.fulfilled, (state, action) => {
      if (action.payload != null && action.payload != undefined) {
        let currentSelectedDocument = { ...state.selectedDocument };
        currentSelectedDocument.html = action.payload.data;
        return {
          ...state,
          selectedDocument: currentSelectedDocument,
        };
      }
    });
  },
});

export const {
  toggleNotes,
  toggleStatus,
  toggleIsDragged,
  setSelectedTab,
  setSelectedDocument,
  setDeSelectDocument,
  setDocumentExpanded,
  setDocumentDeleted,
  setFieldInEditMode,
  setTextCopiedFromLHS,
  setTabFilterEditMode,
  setUserProfileMenuState,
} = dashboardSlice.actions;

export const selectShowNotes = (state: RootState) => state.dashboard.showNotes;

export const selectShowStatus = (state: RootState) => state.dashboard.showStatus;

export const selectFieldInEditMode = (state: RootState) => state.dashboard.fieldInEditMode;

export const selectTextCopiedFromLHS = (state: RootState) => state.dashboard.textCopiedFromLHS;

export const selectIsDocumentDeleted = (state: RootState) => state.dashboard.isDocumentDeleted;

export const selectUserProfileMenuState = (state: RootState) => state.dashboard.isUserProfileMenuOpen;

export const selectDraggedLease = (state: RootState) =>
  state.dashboard.isDragged;

// Get the Currrent Tab
export const selectCurrentTab = (state: RootState) =>
  state.dashboard.selectedTab;

// Get the document Value
export const selectSelectedDocumentId = (state: RootState) =>
  state.dashboard.selectedDocument.id;

// Get the document Lease Status
export const selectSelectedDocumentStatus = (state: RootState) =>
state.dashboard.selectedDocument.status;

//Get Document Value
export const selectSelectedDocumentValues = (state: RootState) =>
  state.dashboard.selectedDocument.values;

export const selectSelectedDocumentAuthor = (state: RootState) =>
  state.dashboard.selectedDocument.author;

export const selectSelectedDocumentMarket = (state: RootState) =>
  state.dashboard.selectedDocument.market;

// Get Document Name
export const selectSelectedDocumentName = (state: RootState) =>
  state.dashboard.selectedDocument.name;

// Get Document HTML
export const selectSelectedDocumentHTML = (state: RootState) =>
  state.dashboard.selectedDocument.html;

// Get the expanded Document Ids
export const selectSelectedDocumentExpandedId = (state: RootState) =>
  state.dashboard.expandedDocumentId;

export const selectPrevDocumentExpandedId = (state: RootState) =>
  state.dashboard.prevExpandedDocumentId;

export const selectSelectedDocumentValuesMapped = createSelector(
  [selectFields, selectSelectedDocumentValues],
  (fields, values) => {
    if (values)
      return values.map((value) => {
        const field = fields.find((field) => field.code == value.field);
        if (field) {
          return {
            field: value.field,
            order: field.order,
            value: value.value,
            prevValue: value.prevValue,
            name: field.name,
            multiline: field.multiLine,
            engineValue: value.engineValue,
          } as FieldValueMetadata;
        } else return {} as FieldValueMetadata;
      });
    else return [];
  }
);

export const selectTabFilterEditState = (state: RootState) =>
  state.dashboard.isTabFilterInEditMode;

export default dashboardSlice.reducer;

// Update the Document Author
export const updateDocumentAuthor = createAsyncThunk(
  "/document/author",
  async (payload: AuthorStatePayload) => {
    let url = UPDATE_DOCUMENT_AUTHOR.replace(
      ":documentId",
      payload.documentId
    ).replace(":authorExternalId", payload.authorExternalId);
    try {
      const response = await AXIOS.put(url);
      if (response.status == 200) {
        return response.data;
      }
    } catch (err) {
      console.error(err);
    }
  }
);

// Update the Document Market
export const updateDocumentMarket = createAsyncThunk(
  "/user/market",
  async (payload: MarketStatePayload) => {
    try {
      const url = UPDATE_DOCUMENT_MARKET.replace(
        ":documentId",
        payload.documentId
      ).replace(":selectedMarketExternalId", payload.marketExternalId);
      const response = await AXIOS.put(url);
      if (response.status == 200) {
        return response.data;
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const fetchDocumentHTML = createAsyncThunk(
  "/document/html",
  async (_, { getState, dispatch }) => {
    const s = getState() as RootState;
    const url = VALUES_HTML.replace(
      API_VARIABLE_SELECTED_DOCUMENT_ID,
      s.dashboard.selectedDocument.id
    ).replace(API_VARIABLE_USER_ID, s.user.externalId);
    try {
      const response = await AXIOS.get(url);
      localStorage.setItem('prevAccessedField', '');
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

export const updateRowPinStatus = createAsyncThunk(
  "/document/row/pin-status",
  async (isPinned: boolean, { getState }) => {
    const s = getState() as RootState;
    let url = "";
    if (isPinned)
      url = ROW_PIN.replace(
        API_VARIABLE_SELECTED_DOCUMENT_ID,
        s.dashboard.selectedDocument.id
      );
    else
      url = ROW_UNPIN.replace(
        API_VARIABLE_SELECTED_DOCUMENT_ID,
        s.dashboard.selectedDocument.id
      );
    try {
      const response = await AXIOS.put(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

export const updateRowReadStatus = createAsyncThunk(
  "/document/row/read-status",
  async (isRead: boolean, { getState }) => {
    const s = getState() as RootState;
    let url = "";
    if (isRead)
      url = MARK_ROW_READ.replace(
        API_VARIABLE_SELECTED_DOCUMENT_ID,
        s.dashboard.selectedDocument.id
      );
    else
      url = MARK_ROW_UNREAD.replace(
        API_VARIABLE_SELECTED_DOCUMENT_ID,
        s.dashboard.selectedDocument.id
      );
    try {
      const response = await AXIOS.put(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

export const rowExpandUpdate = createAsyncThunk(
  "/document/row/children-view-status",
  async (parentDocumentId: string, { getState }) => {
    const s = getState() as RootState;
    let url = ROW_CHILDREN_SHOW.replace(
        API_VARIABLE_SELECTED_DOCUMENT_ID,
        parentDocumentId
      );
    try {
      const response = await AXIOS.put(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

export const rowCollapseUpdate = createAsyncThunk(
  "/document/row/collapse/children-view-status",
  async (parentDocumentId: string, { getState }) => {
    const s = getState() as RootState;
    let url = ROW_CHILDREN_HIDE.replace(
        API_VARIABLE_SELECTED_DOCUMENT_ID,
        parentDocumentId
      );
    try {
      const response = await AXIOS.put(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

export const updateFieldResize = createAsyncThunk(
  "/fields",
  async (fieldData: FieldEntity, { getState }) => {
    const s = getState() as RootState;
    let url =  process.env.REACT_APP_API_BASE_URL + "/fields/" + s.user.externalId;
    try {
      await AXIOS.post(url, fieldData);
      mapOfflineFieldEntityToFieldEntity({...fieldData, isPersisted: true});
    } catch (err) {
      console.error(err);
    }
  }
);
